import React, { useState, useEffect } from "react";
import {
  getCode,
  addUser,
  SaveData,
  getData,
  deleteDataSave,
  getCodeDungThan,
} from "../components/db";
import "../scss/header.scss";
import { Nav, NavItem } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaPrint, FaSave, FaHistory, FaBook } from "react-icons/fa";
import logo from "../assets/logo.png";
import img1 from "../assets/img/img1.png";
import img2 from "../assets/img/img2.png";
import img3 from "../assets/img/img3.png";
import img4 from "../assets/img/img4.png";
import img5 from "../assets/img/img5.png";
import img6 from "../assets/img/img6.png";
import img7 from "../assets/img/img7.png";
import img8 from "../assets/img/img8.png";
import img9 from "../assets/img/img9.png";
import img10 from "../assets/img/img10.png";
import img11 from "../assets/img/img11.png";
import lockIcon from "../assets/img/lockicon.png";
import { useHistory } from "react-router-dom";
import ModalGhiChu from "./ModalGhiChu";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../Slice/UserSlice";
import jwtDecode from "jwt-decode";
import { TiTick } from "react-icons/ti";
import Truncate from "react-truncate-html";
import { saveData } from "../Slice/SaveDataSlice";

export const Header = ({ activeTab, isHistory, data_ }) => {
  const dispatch = useDispatch();

  const clientId =
    "1045246803033-59rqe3ctp30m7djq5ju3b155urg31b91.apps.googleusercontent.com";
  const saveState = useSelector((state) => {
    return state.data;
  });

  const [showModal, setShowModal] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [dataEdit, setDataEdit] = useState("");

  const [code, setCode] = useState("");

  const [data, setData] = useState([]);

  const [aler, setAler] = useState(false);

  const [alerError, setAlerError] = useState(false);

  const [day, setDay] = useState("");

  const [month, setMonth] = useState("");

  const [year, setYear] = useState("");
  const [titleError, setTitleError] = useState("Code đã sử dụng");
  const [saveDataState, setSaveData] = useState(false);
  const [errorSaveData, setErrorSaveData] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [idData, setIdData] = useState("");
  const [alertDelete, setAlertDelete] = useState(false);
  const [reload, setReload] = useState(0);

  const [isOpenGhiChu, setIsOpenGhiChu] = useState(false);

  let history = useHistory();

  useEffect(() => {
    const google = (window.google = window.google ? window.google : {});

    window.onload = function () {
      google.accounts.id.initialize({
        client_id: clientId,
        callback: onSuccess,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
      });
    };

    if (localStorage.getItem("profile")) {
      setDay(
        new Date(
          JSON.parse(localStorage.getItem("profile")).expiredDate
        ).getDate()
      );
      setMonth(
        new Date(
          JSON.parse(localStorage.getItem("profile")).expiredDate
        ).getMonth() + 1
      );
      setYear(
        new Date(
          JSON.parse(localStorage.getItem("profile")).expiredDate
        ).getFullYear()
      );
    }
  }, []);

  useEffect(() => {
    data_(dataEdit);
    setDataEdit(dataEdit);
  }, [dataEdit]);

  useEffect(() => {
    setTimeout(async () => {
      const data = await getData();
      setData(data);
    }, 1000);
  }, [reload]);

  //

  const onSuccess = (response) => {
    const data = jwtDecode(response.credential);
    const action = login(true);
    const user = {
      name: data.name,
      email: data.email,
    };
    dispatch(action);

    addUser(user);
    // });
  };

  const onDelete = async () => {
    await deleteDataSave(idData, (isSuccess) => {
      if (isSuccess) {
        setReload(reload + 1);
        setAlertDelete(true);
      }
    });

    setDeleteData(false);
  };

  const onLogoutSuccess = () => {
    // logOut();
    const google = (window.google = window.google ? window.google : {});

    google.accounts.id.disableAutoSelect();
    const action = login(false);

    dispatch(action);

    localStorage.clear();
    window.location.reload();
  };

  const handleCode = async () => {
    if (showModal === "OPEN_DUNG_THAN") {
      if (code) {
        console.log("alo");
        await getCodeDungThan(code, (value) => {
          if (value === "success") {
            setAler(true);
          } else if (value === "fail") {
            setAlerError(true);
            setTitleError("Code không hợp lệ");
          } else if (value === "exist") {
            setAlerError(true);
            setTitleError("Code đã sử dụng");
          }
        });
      } else {
        setAlerError(true);
      }
    } else {
      if (code) {
        await getCode(code, (value) => {
          if (value === "success") {
            setAler(true);
          } else if (value === "fail") {
            setAlerError(true);
            setTitleError("Code không hợp lệ");
          } else if (value === "exist") {
            setAlerError(true);
            setTitleError("Code đã sử dụng");
          }
        });
      } else {
        setAlerError(true);
      }
    }
  };

  const handleAlert = () => {
    setAler(false);

    window.location.reload();
  };

  const isLoggedIn = localStorage.getItem("profile");
  const isActive = JSON.parse(localStorage.getItem("profile"))?.isActive;
  const isActiveHoaGiap = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveHoaGiap;
  const isActiveThanSat = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveThanSat;
  const isActiveNguoiNoiTieng = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveNguoiNoiTieng;

  const isActiveTieuNhi = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveTieuNhi;

  const isActiveTinhCach = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveTinhCach;

  const isActiveDungThanDieuHau = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveDungThanDieuHau;

  const isActiveNguHanh = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveNguHanh;

  const SaveDataToFireBase = () => {
    const data = JSON.parse(sessionStorage.getItem("dataSave"));
    if (data) {
      SaveData(data, (isSuccess) => {
        if (isSuccess) {
          setSaveData(true);
          dispatch(saveData(true));
        }
      });
    } else {
      setErrorSaveData(true);
    }
  };

  return (
    <div style={{ width: "100%" }} className="hide-to-print">
      <Nav id="header__">
        <div id="header__left">
          <img src={logo} alt="logo" />
          <p>TUONG MINH FENG SHUI</p>
        </div>
        <div className="link__">
          <NavItem
            className="border-right link-header"
            onClick={() => activeTab("/")}
          >
            Lá Số Bát Tự
            {/* <Link className="link-header" to="/">
              Lá số bát tự
            </Link> */}
          </NavItem>
          <NavItem
            className="border-right link-header"
            onClick={() => {
              isActiveHoaGiap ? activeTab("hoagiap") : activeTab("/");
            }}
          >
            {/* <Link
              className="link-header"
      
              // to={isActiveHoaGiap ? "/60-hoagiap" : ""}
            >
              60 Hoa Giáp
            </Link> */}
            Hoa Giáp
            {!isActiveHoaGiap && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -45,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>
          <NavItem
            tem
            className="border-right link-header"
            onClick={() => {
              isActiveThanSat ? activeTab("thansat") : activeTab("/");
            }}
          >
            Thần Sát
            {!isActiveThanSat && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -38,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>

          <NavItem
            tem
            className="border-right link-header"
            onClick={() => {
              isActiveNguoiNoiTieng ? activeTab("celebrity") : activeTab("/");
            }}
          >
            Bát Tự Người Nổi Tiếng
            {!isActiveNguoiNoiTieng && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -103,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>

          <NavItem
            className="border-right link-header"
            onClick={() =>
              isActiveTieuNhi ? activeTab("tieunhi") : activeTab("/")
            }
          >
            Tiểu nhi quan sát
            {!isActiveTieuNhi && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -65,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>
          <NavItem
            tem
            className="border-right link-header"
            onClick={() =>
              isActiveTinhCach ? activeTab("tinhcach") : activeTab("/")
            }
          >
            Tính cách 10 ngày sinh
            {!isActiveTinhCach && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -91,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>
          <NavItem
            tem
            className="border-right link-header"
            onClick={() =>
              isActiveDungThanDieuHau
                ? activeTab("dungthandieuhau")
                : activeTab("/")
            }
          >
            Dụng Thần Hình Ảnh
            {!isActiveDungThanDieuHau && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -80,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>
          <NavItem
            tem
            className="border-right link-header"
            onClick={() =>
              isActiveNguHanh ? activeTab("nguhanh") : activeTab("/")
            }
          >
            Ngũ Hành
            {!isActiveNguHanh && (
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -45,
                  marginTop: -15,
                }}
                src={lockIcon}
                alt=""
              />
            )}
          </NavItem>
        </div>
        <div id="image__header">
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
          <img src={img7} alt="" />
          <img src={img8} alt="" />
          <img src={img9} alt="" />
          <img src={img10} alt="" />
          <img src={img11} alt="" />
        </div>
        <div
          id="hover__print"
          style={{ marginTop: "5px", marginLeft: "10px", zIndex: 99999 }}
        >
          <button
            onClick={() => window.print()}
            id="header__right"
            style={{ cursor: "pointer", zIndex: 99999 }}
          >
            <FaPrint
              id="header__right"
              style={{
                width: "25px",
                height: "25px",
                opacity: "0.7",
                cursor: "pointer",
              }}
            />
          </button>
        </div>
        {isLoggedIn && (
          <>
            <div
              id="hover__print"
              style={{ marginTop: "5px", marginLeft: "10px", zIndex: 99999 }}
            >
              <button
                disabled={saveState}
                onClick={() => SaveDataToFireBase()}
                id="header__right"
                style={{ cursor: "pointer" }}
                title={saveState ? "Vui lòng xem bát tự" : "lưu dữ liệu"}
              >
                <FaSave
                  style={{
                    width: "25px",
                    height: "25px",
                    opacity: "0.7",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
            <div
              id="hover__print"
              style={{ marginTop: "5px", marginLeft: "10px", zIndex: 99999 }}
            >
              <button
                title="xem lịch sử"
                onClick={async () => {
                  const data = await getData();
                  setData(data);
                  setShowHistory(true);
                }}
                id="header__right"
                style={{ cursor: "pointer", zIndex: 99999 }}
              >
                <FaHistory
                  style={{
                    width: "25px",
                    height: "25px",
                    opacity: "0.7",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
            <div
              id="hover__print"
              style={{
                marginTop: "5px",
                marginLeft: "10px",
                cursor: "pointer",
                zIndex: 99999,
              }}
            >
              <button
                title="Ghi chú"
                onClick={() => setIsOpenGhiChu(true)}
                id="header__right"
                style={{ cursor: "pointer", zIndex: 99999 }}
              >
                <FaBook
                  style={{
                    width: "25px",
                    height: "25px",
                    opacity: "0.7",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </>
        )}
      </Nav>

      <div id="login__google">
        {isLoggedIn ? (
          <>
            {!isActive ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  color: "#3c4b64",
                  fontSize: "120%",
                  fontWeight: "bold",
                }}
              >
                Tài khoản của bạn đã hết hạn
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  color: "#3c4b64",
                  fontSize: "120%",
                  fontWeight: "bold",
                }}
              >
                Tài khoản của bạn sẽ hết hạn vào Ngày {day} Tháng {month} Năm{" "}
                {year}
              </div>
            )}
            <Dropdown style={{ width: "150px!important" }}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Xin chào {JSON.parse(localStorage.getItem("profile"))?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowModal("open modal")}>
                  Nhập Code
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowModal("OPEN_DUNG_THAN")}>
                  Nhập Code Dụng Thần
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onLogoutSuccess()}>
                  Đăng xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>{" "}
          </>
        ) : (
          <div id="signInDiv"></div>
        )}
      </div>

      <Modal size="lg" show={showModal} onHide={() => setShowModal("")}>
        <Modal.Header
          style={{ background: "#321FDB", color: "white" }}
          closeButton
        >
          <Modal.Title>
            Thông tin sử dụng Website Huyền không phi tinh
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ wordWrap: "break-word" }}>
          <div style={{ textAlign: "left" }}>
            Xin chào, {JSON.parse(localStorage.getItem("profile"))?.name}. Dưới
            đây là thông tin sử dụng WebSite của chúng tôi, sau thông tin này
            bạn có thể liên hệ với chúng tôi để có thể sử dụng web một cách toàn
            vẹn nhất có thể bạn nhé!!!! Xin mời bạn nhập Code cưới này và press
            nút gửi đi để có thể sử dụng web
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <input
              onChange={(e) => setCode(e.target.value)}
              className="input-code"
              placeholder={
                showModal === "OPEN_DUNG_THAN"
                  ? "Nhập Code dụng thần..."
                  : "Nhập Code kích hoạt..."
              }
            />
            <Button
              onClick={handleCode}
              style={{
                marginLeft: "10px",
                width: 60,
                height: 40,
              }}
              variant="primary"
            >
              Gửi
            </Button>{" "}
            {aler ? (
              <SweetAlert
                type={"success"}
                title={"Kích hoạt thành công"}
                onConfirm={handleAlert}
              />
            ) : (
              ""
            )}
            {alerError ? (
              <SweetAlert
                type={"warning"}
                title={titleError}
                onConfirm={() => setAlerError(false)}
              />
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal("")}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showHistory} onHide={() => setShowHistory(false)}>
        <Modal.Header
          style={{ background: "#321FDB", color: "white" }}
          closeButton
        >
          <Modal.Title>Lịch sử dữ liệu: </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ wordWrap: "break-word" }}>
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              flexDirection: "column",
              rowGap: "15px",
              maxHeight: "700px",
              overflow: "auto",
            }}
          >
            <div
              className="history-data"
              style={{
                display: "flex",
                columnGap: "10px",
              }}
            >
              <table
                style={{ tableLayout: "fixed" }}
                class="table table-responsive table-bordered"
              >
                {data && data.length > 0 && (
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Họ tên</th>
                      <th scope="col">Bát tự</th>
                      <th scope="col">Nơi sinh</th>
                      <th scope="col">Nạp âm</th>
                      <th scope="col">Trường sinh</th>
                      <th scope="col">Thần Sát</th>
                      <th scope="col">Ghi chú</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td style={{ minWidth: "200px" }}>
                            {item.name ? item.name : "Không có"}
                          </td>
                          <td style={{ minWidth: "180px" }}>{item.data}</td>
                          <td style={{ minWidth: "180px" }}>
                            {item.born ? item.born : "Không có"}
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            {item.napAm ? (
                              <TiTick fontSize={24} color={"red"} />
                            ) : (
                              "Không có"
                            )}
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            {item.truongSinh ? (
                              <TiTick fontSize={24} color={"red"} />
                            ) : (
                              "Không có"
                            )}
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            {item.thanSat ? (
                              <TiTick fontSize={24} color={"red"} />
                            ) : (
                              "Không có nơi sinh"
                            )}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Truncate
                              lines={3}
                              dangerouslySetInnerHTML={{
                                __html: item.note,
                              }}
                            />
                          </td>
                          <td
                            style={{ minWidth: "250px", textAlign: "center" }}
                          >
                            <button
                              onClick={() => {
                                sessionStorage.setItem(
                                  "dataHistory",
                                  JSON.stringify({
                                    date: item.data.split(" ")[0],
                                    time: item.data.split(" ")[1],
                                    name: item.name,
                                    born: item.born,
                                    napAm: item.napAm,
                                    truongSinh: item.truongSinh,
                                    thanSat: item.thanSat,
                                    note: item.note,
                                  })
                                );
                                sessionStorage.setItem(
                                  "dataSave",
                                  JSON.stringify({
                                    data: item.data,
                                    email: JSON.parse(
                                      localStorage.getItem("profile")
                                    )?.email,
                                    name: item.name,
                                    born: item.born,
                                    napAm: item.napAm,
                                    truongSinh: item.truongSinh,
                                    thanSat: item.thanSat,
                                    note: item.note,
                                  })
                                );

                                isHistory(1);
                                setShowHistory(false);
                                setDataEdit(item.note);
                              }}
                              type="button"
                              class="btn btn-primary"
                            >
                              Xem
                            </button>
                            <button
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                setIdData(item.id);
                                setDeleteData(!deleteData);
                              }}
                              type="button"
                              class="btn btn-danger"
                            >
                              Xóa
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <h3>Không có dữ liệu</h3>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHistory(false)}>
            Đóng
          </Button>
        </Modal.Footer>
        {deleteData && (
          <SweetAlert
            type={"error"}
            showCancel
            title={"Bạn có muốn xóa dữ liệu?"}
            onConfirm={() => onDelete()}
            onCancel={() => setDeleteData(false)}
            closeOnClickOutside
            cancelBtnText="Hủy"
            cancelBtnBsStyle="danger"
          />
        )}
        {alertDelete && (
          <SweetAlert
            type={"success"}
            showCancel
            showConfirm={false}
            title={"Xóa thành công"}
            onCancel={() => setAlertDelete(false)}
            cancelBtnText="OK"
            cancelBtnBsStyle="primary"
          />
        )}
      </Modal>

      <ModalGhiChu
        open={isOpenGhiChu}
        dataEdit_={dataEdit}
        close={(value) => {
          data_("");
          setIsOpenGhiChu(value);
        }}
        save={(value, data) => {
          data_(data);
          setIsOpenGhiChu(value);
        }}
      />

      {saveDataState && (
        <SweetAlert
          type={"success"}
          title={"Lưu thành công"}
          onConfirm={() => setSaveData(false)}
        />
      )}
      {errorSaveData && (
        <SweetAlert
          type={"error"}
          title={"Vui lòng xem bát tự"}
          onConfirm={() => setErrorSaveData(false)}
        />
      )}
    </div>
  );
};
