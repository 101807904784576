import firebase_store from "../firebase";

const db = firebase_store;

export const getCode = async (code, onSuccess) => {
  await db
    .collection("redeemCode_BatTu_Fengshui")
    .doc(code)
    .get()
    .then((doc) => {
      if (doc.exists) {

        if (doc?.data()?.level === 1 && doc?.data()?.isActive === false) {
          updateUser(3, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          updateCode(code, doc.data().level, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          onSuccess("success");

        } else if (
          doc?.data()?.level === 2 &&
          doc?.data()?.isActive === false
        ) {

          updateUser(6, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          updateCode(code, doc.data().level, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          onSuccess("success");

        } else if (
          doc?.data()?.level === 3 &&
          doc?.data()?.isActive === false
        ) {

          updateUser(12, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          updateCode(code, doc.data().level, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          onSuccess("success");

        } else if (
          doc?.data()?.level === 4 &&
          doc?.data()?.isActive === false
        ) {

          updateUser(36, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          updateCode(code, doc.data().level, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          onSuccess("success");

        } else if (
          doc?.data()?.level === 5 &&
          doc?.data()?.isActive === false
        ) {

          updateUser(1200, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          updateCode(code, doc.data().level, doc?.data()?.enableThanSat, doc?.data()?.enableHoaGiap, doc?.data()?.enableNguoiNoiTieng, doc?.data()?.enableTieuNhi, doc?.data()?.enableTinhCach, doc?.data()?.enableDungThanDieuHau, doc?.data()?.enableNguHanh);
          onSuccess("success");

        }
        if (doc?.data()?.isActive === true) {
          onSuccess('exist')
        }

      }
      else {
        onSuccess("fail");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
  // return 'haha';
};

export const getCodeDungThan = async (code, onSuccess) => {
  await db
    .collection("code_dungThanBatTu")
    .doc(code)
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (doc?.data()?.level === 1 && doc?.data()?.isActive === false) {
          updateUserV2(3);
          updateCodeV2(code, doc?.data()?.level)
          onSuccess("success");
        } else if (doc?.data()?.level === 2 && doc?.data()?.isActive === false) {
          updateUserV2(6);
          updateCodeV2(code, doc?.data()?.level)
          onSuccess("success");
        } else if (doc?.data()?.level === 3 && doc?.data()?.isActive === false) {
          updateUserV2(12);
          updateCodeV2(code, doc?.data()?.level)
          onSuccess("success");
        } else if (doc?.data()?.level === 4 && doc?.data()?.isActive === false) {
          updateUserV2(36);
          updateCodeV2(code, doc?.data()?.level)
          onSuccess("success");
        } else if (doc?.data()?.level === 5 && doc?.data()?.isActive === false) {
          updateUserV2(1200);
          updateCodeV2(code, doc?.data()?.level)
          onSuccess("success");
        }
        if (doc?.data()?.isActive === true) {
          onSuccess('exist')
        }
      } else {
        onSuccess("fail");
      }
    })
}

const updateCode = (code, level, isActiveThanSat, isActiveHoaGiap, isActiveNguoiNoiTieng, isActiveTieuNhi, isActiveTinhCach, isActiveDungThanDieuHau, isActiveNguHanh) => {
  db.collection("redeemCode_BatTu_Fengshui").doc(code).set({
    isActive: true,
    level: level,
    isActiveThanSat: isActiveThanSat !== undefined && isActiveThanSat === true,
    isActiveHoaGiap: isActiveHoaGiap !== undefined && isActiveHoaGiap === true,
    isActiveNguoiNoiTieng: isActiveNguoiNoiTieng !== undefined && isActiveNguoiNoiTieng === true,
    isActiveTieuNhi: isActiveTieuNhi !== undefined && isActiveTieuNhi === true,
    isActiveTinhCach: isActiveTinhCach !== undefined && isActiveTinhCach === true,
    isActiveDungThanDieuHau: isActiveDungThanDieuHau !== undefined && isActiveDungThanDieuHau === true,
    isActiveNguHanh: isActiveNguHanh !== undefined && isActiveNguHanh === true,
  });
};

const updateCodeV2 = (code, level) => {
  db.collection("code_dungThanBatTu").doc(code).set({
    isActive: true,
    level: level
  });
}

export const addUser = (user) => {
  db.collection("User_BatTu_FengSui")
    .doc(user.email)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let currenDate = new Date();
        let expiredDatee = new Date(
          new Date(doc.data().expiredDate.seconds * 1000).toString()
        );

        let profileUser = {
          name: doc.data().name,
          email: doc.data().email,
          activeDate: new Date(doc.data().activeDate.seconds * 1000).toString(),
          isActive: doc.data().isActive,
          isActiveThanSat: doc.data().isActiveThanSat,
          isActiveHoaGiap: doc.data().isActiveHoaGiap,
          isActiveNguoiNoiTieng: doc.data()?.isActiveNguoiNoiTieng,
          isActiveTieuNhi: doc.data()?.isActiveTieuNhi,
          isActiveTinhCach: doc.data()?.isActiveTinhCach,
          isActiveDungThanDieuHau: doc.data()?.isActiveDungThanDieuHau,
          isActiveNguHanh: doc.data()?.isActiveNguHanh,
          isActiveDungThan: doc.data()?.isActiveDungThan,

          expiredDate: new Date(
            doc.data().expiredDate.seconds * 1000
          ).toString(),
        };

        if (expiredDatee > currenDate) {
          localStorage.setItem("profile", JSON.stringify(profileUser));
          window.location.reload(false);
        } else {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              name: doc.data().name,
              email: doc.data().email,
              activeDate: new Date(
                new Date(doc.data().activeDate.seconds * 1000).toString()
              ),
              isActive: false,
              expiredDate: new Date(
                new Date(doc.data().expiredDate.seconds * 1000).toString()
              ),
            })
          );

          db.collection("User_BatTu_FengSui")
            .doc(user.email)
            .set({
              name: doc.data().name,
              email: doc.data().email,
              activeDate: new Date(
                new Date(doc.data().activeDate.seconds * 1000).toString()
              ),
              isActive: false,
              expiredDate: new Date(
                new Date(doc.data().expiredDate.seconds * 1000).toString()
              ),
            });
          window.location.reload()

            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        let profileUserNew = {
          name: user.name,
          email: user.email,
          isActive: true,
          isActiveThanSat: false,
          isActiveHoaGiap: false,
          isActiveNguoiNoiTieng: false,
          isActiveTieuNhi: false,
          isActiveTinhCach: false,
          isActiveDungThanDieuHau: false,
          isActiveNguHanh: false,
          isActiveDungThan: false,
          activeDate: new Date(),
          expiredDate: new Date(new Date().setDate(new Date().getDate() + 7)),
        };
        // doc.data() will be undefined in this case
        db.collection("User_BatTu_FengSui")
          .doc(user.email)
          .set(profileUserNew)
          .then(() => {
            localStorage.setItem("profile", JSON.stringify(profileUserNew));
            window.location.reload();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const updateUser = (level, isActiveThanSat, isActiveHoaGiap, isActiveNguoiNoiTieng, isActiveTieuNhi, isActiveTinhCach, isActiveDungThanDieuHau, isActiveNguHanh) => {
  // return
  let currentExpiredDate = new Date(
    JSON.parse(localStorage.getItem("profile"))?.expiredDate
  ).setMonth(
    new Date(
      JSON.parse(localStorage.getItem("profile"))?.expiredDate
    ).getMonth() + level
  );
  let newExpiredDate = new Date(currentExpiredDate);
  const dungThan = JSON.parse(localStorage.getItem("profile"))?.isActiveDungThan


  let newUser = {
    name: JSON.parse(localStorage.getItem("profile"))?.name,
    email: JSON.parse(localStorage.getItem("profile"))?.email,
    isActive: true,
    isActiveThanSat: isActiveThanSat !== undefined && isActiveThanSat === true,
    isActiveHoaGiap: isActiveHoaGiap !== undefined && isActiveHoaGiap === true,
    isActiveNguoiNoiTieng: isActiveNguoiNoiTieng !== undefined && isActiveNguoiNoiTieng === true,
    isActiveTieuNhi: isActiveTieuNhi !== undefined && isActiveTieuNhi === true,
    isActiveTinhCach: isActiveTinhCach !== undefined && isActiveTinhCach === true,
    isActiveDungThanDieuHau: isActiveDungThanDieuHau !== undefined && isActiveDungThanDieuHau === true,
    isActiveNguHanh: isActiveNguHanh !== undefined && isActiveNguHanh === true,
    isActiveDungThan: dungThan,
    activeDate: new Date(
      JSON.parse(localStorage.getItem("profile"))?.activeDate
    ),
    expiredDate: newExpiredDate,
  };

  db.collection("User_BatTu_FengSui")
    .doc(JSON.parse(localStorage.getItem("profile"))?.email)
    .set(newUser);

  localStorage.setItem("profile", JSON.stringify(newUser));
};

const updateUserV2 = (level) => {

  let currentExpiredDate = new Date(
    JSON.parse(localStorage.getItem("profile"))?.expiredDate
  ).setMonth(
    new Date(
      JSON.parse(localStorage.getItem("profile"))?.expiredDate
    ).getMonth() + level
  );

  let newExpiredDate = new Date(currentExpiredDate);
  const newUser = {
    ...JSON.parse(localStorage.getItem("profile")),
    isActiveDungThan: true,
    activeDate: new Date(
      JSON.parse(localStorage.getItem("profile"))?.activeDate
    ),
    expiredDate: newExpiredDate,
  }

  db.collection("User_BatTu_FengSui")
    .doc(JSON.parse(localStorage.getItem("profile"))?.email)
    .set(newUser);

  localStorage.setItem("profile", JSON.stringify(newUser));
}

export const SaveData = (data, onSuccess) => {
  db.collection("Data_BatTu")
    .get()
    .then((doc) => {
      const id = makeid(5)
      const content = JSON.parse(sessionStorage.getItem('note'))
      let updateData = {
        id: id,
        email: data.email,
        data: data.data,
        name: data.name,
        born: data.born,
        napAm: data.napAm,
        truongSinh: data.truongSinh,
        thanSat: data.thanSat,
        note: content ? content : data.note
      };
      db.collection("Data_BatTu")
        .doc(id)
        .set(updateData)
        .then(() => {
          onSuccess(true)
        })
        .catch((err) => {
          onSuccess(false)
        });

    })
}

export const getData = async () => {
  let markers = [];
  const email = JSON.parse(localStorage.getItem("profile"))?.email
  await db.collection('Data_BatTu')
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        if (doc.data().email === email) {

          markers.push(doc.data());
        }
      });
    });

  markers.filter((item) => {
    return item.email === email
  })
  // markers = [...new Map(markers.map(item =>
  //   [item['data'], item])).values()];


  return markers

}

export const deleteDataSave = async (id, onSuccess) => {
  let query = db.collection('Data_BatTu').where('id', '==', id);
  query.get().then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      doc.ref.delete();
    });
  });
  onSuccess(true)
}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}