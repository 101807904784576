import Const from "./Const";
import moment, { min } from "moment";
export function YearToCan(year) {
  year += "";
  const last = year.split("");

  let result = last[last.length - 1];

  switch (+result) {
    case Const.Can.Canh.code:
      return Const.Can.Canh;
    case Const.Can.Tan.code:
      return Const.Can.Tan;
    case Const.Can.Nham.code:
      return Const.Can.Nham;
    case Const.Can.Qui.code:
      return Const.Can.Qui;
    case Const.Can.Giap.code:
      return Const.Can.Giap;
    case Const.Can.At.code:
      return Const.Can.At;
    case Const.Can.Binh.code:
      return Const.Can.Binh;
    case Const.Can.Dinh.code:
      return Const.Can.Dinh;
    case Const.Can.Mau.code:
      return Const.Can.Mau;
    case Const.Can.Ky.code:
      return Const.Can.Ky;
    default:
      break;
  }
}

export function YearToChi(year) {
  let result = year % 12;

  switch (result) {
    case Const.Chi.Ty.code:
      return Const.Chi.Ty;
    case Const.Chi.Suu.code:
      return Const.Chi.Suu;
    case Const.Chi.Dan.code:
      return Const.Chi.Dan;
    case Const.Chi.Mao.code:
      return Const.Chi.Mao;
    case Const.Chi.Thin.code:
      return Const.Chi.Thin;
    case Const.Chi.Ti.code:
      return Const.Chi.Ti;
    case Const.Chi.Ngo.code:
      return Const.Chi.Ngo;
    case Const.Chi.Mui.code:
      return Const.Chi.Mui;
    case Const.Chi.Than.code:
      return Const.Chi.Than;
    case Const.Chi.Dau.code:
      return Const.Chi.Dau;
    case Const.Chi.Tuat.code:
      return Const.Chi.Tuat;
    case Const.Chi.Hoi.code:
      return Const.Chi.Hoi;
    default:
      break;
  }
}

export function getNamAp(canChiDay) {
  const result = Const.Que.find((v) => v.queViet === canChiDay);

  return result;
}

export function DayToCanChi(selectedTime) {
  const time = moment(selectedTime)
  const timeDefault = moment([0, 0, 1]).valueOf();

  const timeCalculator = moment(time).valueOf();

  let result = Math.round(
    ((timeCalculator - timeDefault) / (1000 * 60 * 60 * 24)) % 60
  );
  // alert("🚀 ~ file: convert.js ~ line 87 ~ DayToCanChi ~ result", ((timeCalculator - test) / (1000 * 60 * 60 * 24)) % 60)

  if ((result + 10) % 60 === 0) {

    return Const.CanChiArr[59];

  } else {

    return Const.CanChiArr.filter((v) => v.code === (result + 10) % 60)[0];
  }
}

export function getQue(canTV, chiTV) {
  let tempCanTV = Const.CanArr.find((v) => v.name === canTV);
  let tempChiTV = Const.ChiArr.find((v) => v.name === chiTV);

  if (!tempChiTV || !tempCanTV) {
    return null;
  }
  const tenHanViet = tempCanTV.hanViet + " " + tempChiTV.hanViet;
  const result = Const.Que.find((v) => v.que === tenHanViet);
  return result;
}

export function convertHour(Hour) {
  switch (+Hour) {
    case 23:
    case 0:
      return Const.HourArr.GioTy;
    case 1:
    case 2:
      return Const.HourArr.GioSuu;
    case 3:
    case 4:
      return Const.HourArr.GioDan;
    case 5:
    case 6:
      return Const.HourArr.GioMao;
    case 7:
    case 8:
      return Const.HourArr.GioThin;
    case 9:
    case 10:
      return Const.HourArr.GioTi;
    case 11:
    case 12:
      return Const.HourArr.GioNgo;
    case 13:
    case 14:
      return Const.HourArr.GioMui;
    case 15:
    case 16:
      return Const.HourArr.GioThan;
    case 17:
    case 18:
      return Const.HourArr.GioDau;
    case 19:
    case 20:
      return Const.HourArr.GioTuat;
    case 21:
    case 22:
      return Const.HourArr.GioHoi;

    default:
      break;
  }
}

export function HourToCanChi(canDay, chiHour) {

  if (chiHour === 23) {
    const index = Const.thuTuThienCan.findIndex((item) => item === canDay);
    (index + 1) === 10 ? canDay = Const.thuTuThienCan[0] : canDay = Const.thuTuThienCan[index + 1];
  }

  const CanHour = convertHour(chiHour).code + 1;
  if (canDay === Const.Can.Giap.name || canDay === Const.Can.Ky.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.giapTy;
      }
      case 2: {
        return Const.CanChi.atSuu;
      }
      case 3: {
        return Const.CanChi.binhDan;
      }
      case 4: {
        return Const.CanChi.dinhMao;
      }
      case 5: {
        return Const.CanChi.mauThin;
      }
      case 6: {
        return Const.CanChi.kyTi;
      }
      case 7: {
        return Const.CanChi.canhNgo;
      }
      case 8: {
        return Const.CanChi.tanMui;
      }
      case 9: {
        return Const.CanChi.nhamThan;
      }
      case 10: {
        return Const.CanChi.quyDau;
      }
      case 11: {
        return Const.CanChi.giapTuat;
      }
      case 12: {
        return Const.CanChi.atHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.At.name || canDay === Const.Can.Canh.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.binhTy;
      }
      case 2: {
        return Const.CanChi.dinhSuu;
      }
      case 3: {
        return Const.CanChi.mauDan;
      }
      case 4: {
        return Const.CanChi.kyMao;
      }
      case 5: {
        return Const.CanChi.canhThin;
      }
      case 6: {
        return Const.CanChi.tanTi;
      }
      case 7: {
        return Const.CanChi.nhamNgo;
      }
      case 8: {
        return Const.CanChi.quiMui;
      }
      case 9: {
        return Const.CanChi.giapThan;
      }
      case 10: {
        return Const.CanChi.atDau;
      }
      case 11: {
        return Const.CanChi.binhTuat;
      }
      case 12: {
        return Const.CanChi.dinhHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Binh.name || canDay === Const.Can.Tan.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.mauTy;
      }
      case 2: {
        return Const.CanChi.kySuu;
      }
      case 3: {
        return Const.CanChi.canhDan;
      }
      case 4: {
        return Const.CanChi.tanMao;
      }
      case 5: {
        return Const.CanChi.nhamThin;
      }
      case 6: {
        return Const.CanChi.quyTi;
      }
      case 7: {
        return Const.CanChi.giapNgo;
      }
      case 8: {
        return Const.CanChi.atMui;
      }
      case 9: {
        return Const.CanChi.binhThan;
      }
      case 10: {
        return Const.CanChi.dinhDau;
      }
      case 11: {
        return Const.CanChi.mauTuat;
      }
      case 12: {
        return Const.CanChi.kyHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Dinh.name || canDay === Const.Can.Nham.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.canhTy;
      }
      case 2: {
        return Const.CanChi.tanSuu;
      }
      case 3: {
        return Const.CanChi.nhamDan;
      }
      case 4: {
        return Const.CanChi.quiMao;
      }
      case 5: {
        return Const.CanChi.giapThin;
      }
      case 6: {
        return Const.CanChi.atTi;
      }
      case 7: {
        return Const.CanChi.binhNgo;
      }
      case 8: {
        return Const.CanChi.dinhMui;
      }
      case 9: {
        return Const.CanChi.mauThan;
      }
      case 10: {
        return Const.CanChi.kydau;
      }
      case 11: {
        return Const.CanChi.canhTuat;
      }
      case 12: {
        return Const.CanChi.tanHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Mau.name || canDay === Const.Can.Qui.name) {

    switch (CanHour) {
      case 1: {
        return Const.CanChi.nhamTy;
      }
      case 2: {
        return Const.CanChi.quiSuu;
      }
      case 3: {
        return Const.CanChi.giapDan;
      }
      case 4: {
        return Const.CanChi.atMao;
      }
      case 5: {
        return Const.CanChi.binhThin;
      }
      case 6: {
        return Const.CanChi.dinhTi;
      }
      case 7: {
        return Const.CanChi.mauNgo;
      }
      case 8: {
        return Const.CanChi.kyMui;
      }
      case 9: {
        return Const.CanChi.canhThan;
      }
      case 10: {
        return Const.CanChi.tanDau;
      }
      case 11: {
        return Const.CanChi.nhamTuat;
      }
      case 12: {
        return Const.CanChi.quyHoi;
      }
      default:
        break;
    }
  }
}

export function HourToCanChi_(canDay, chiHour) {



  const CanHour = convertHour(chiHour).code + 1;
  if (canDay === Const.Can.Giap.name || canDay === Const.Can.Ky.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.giapTy;
      }
      case 2: {
        return Const.CanChi.atSuu;
      }
      case 3: {
        return Const.CanChi.binhDan;
      }
      case 4: {
        return Const.CanChi.dinhMao;
      }
      case 5: {
        return Const.CanChi.mauThin;
      }
      case 6: {
        return Const.CanChi.kyTi;
      }
      case 7: {
        return Const.CanChi.canhNgo;
      }
      case 8: {
        return Const.CanChi.tanMui;
      }
      case 9: {
        return Const.CanChi.nhamThan;
      }
      case 10: {
        return Const.CanChi.quyDau;
      }
      case 11: {
        return Const.CanChi.giapTuat;
      }
      case 12: {
        return Const.CanChi.atHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.At.name || canDay === Const.Can.Canh.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.binhTy;
      }
      case 2: {
        return Const.CanChi.dinhSuu;
      }
      case 3: {
        return Const.CanChi.mauDan;
      }
      case 4: {
        return Const.CanChi.kyMao;
      }
      case 5: {
        return Const.CanChi.canhThin;
      }
      case 6: {
        return Const.CanChi.tanTi;
      }
      case 7: {
        return Const.CanChi.nhamNgo;
      }
      case 8: {
        return Const.CanChi.quiMui;
      }
      case 9: {
        return Const.CanChi.giapThan;
      }
      case 10: {
        return Const.CanChi.atDau;
      }
      case 11: {
        return Const.CanChi.binhTuat;
      }
      case 12: {
        return Const.CanChi.dinhHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Binh.name || canDay === Const.Can.Tan.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.mauTy;
      }
      case 2: {
        return Const.CanChi.kySuu;
      }
      case 3: {
        return Const.CanChi.canhDan;
      }
      case 4: {
        return Const.CanChi.tanMao;
      }
      case 5: {
        return Const.CanChi.nhamThin;
      }
      case 6: {
        return Const.CanChi.quyTi;
      }
      case 7: {
        return Const.CanChi.giapNgo;
      }
      case 8: {
        return Const.CanChi.atMui;
      }
      case 9: {
        return Const.CanChi.binhThan;
      }
      case 10: {
        return Const.CanChi.dinhDau;
      }
      case 11: {
        return Const.CanChi.mauTuat;
      }
      case 12: {
        return Const.CanChi.kyHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Dinh.name || canDay === Const.Can.Nham.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.canhTy;
      }
      case 2: {
        return Const.CanChi.tanSuu;
      }
      case 3: {
        return Const.CanChi.nhamDan;
      }
      case 4: {
        return Const.CanChi.quiMao;
      }
      case 5: {
        return Const.CanChi.giapThin;
      }
      case 6: {
        return Const.CanChi.atTi;
      }
      case 7: {
        return Const.CanChi.binhNgo;
      }
      case 8: {
        return Const.CanChi.dinhMui;
      }
      case 9: {
        return Const.CanChi.mauThan;
      }
      case 10: {
        return Const.CanChi.kydau;
      }
      case 11: {
        return Const.CanChi.canhTuat;
      }
      case 12: {
        return Const.CanChi.tanHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Mau.name || canDay === Const.Can.Qui.name) {

    switch (CanHour) {
      case 1: {
        return Const.CanChi.nhamTy;
      }
      case 2: {
        return Const.CanChi.quiSuu;
      }
      case 3: {
        return Const.CanChi.giapDan;
      }
      case 4: {
        return Const.CanChi.atMao;
      }
      case 5: {
        return Const.CanChi.binhThin;
      }
      case 6: {
        return Const.CanChi.dinhTi;
      }
      case 7: {
        return Const.CanChi.mauNgo;
      }
      case 8: {
        return Const.CanChi.kyMui;
      }
      case 9: {
        return Const.CanChi.canhThan;
      }
      case 10: {
        return Const.CanChi.tanDau;
      }
      case 11: {
        return Const.CanChi.nhamTuat;
      }
      case 12: {
        return Const.CanChi.quyHoi;
      }
      default:
        break;
    }
  }
}

export function MonthToCanChi(canYear, month) {
  let code = 0;
  if (canYear === "Canh") code = 0;
  else if (canYear === "Tân") code = 1;
  else if (canYear === "Nhâm") code = 2;
  else if (canYear === "Quý") code = 3;
  else if (canYear === "Giáp") code = 4;
  else if (canYear === "Ất") code = 5;
  else if (canYear === "Bính") code = 6;
  else if (canYear === "Đinh") code = 7;
  else if (canYear === "Mậu") code = 8;
  else if (canYear === "Kỷ") code = 9;

  const monthNew = month + 1;
  if (code === Const.Can.Giap.code || code === Const.Can.Ky.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.dinhSuu;
      }
      case 2: {
        return Const.CanChi.binhDan;
      }
      case 3: {
        return Const.CanChi.dinhMao;
      }
      case 4: {
        return Const.CanChi.mauThin;
      }
      case 5: {
        return Const.CanChi.kyTi;
      }
      case 6: {
        return Const.CanChi.canhNgo;
      }
      case 7: {
        return Const.CanChi.tanMui;
      }
      case 8: {
        return Const.CanChi.nhamThan;
      }
      case 9: {
        return Const.CanChi.quyDau;
      }
      case 10: {
        return Const.CanChi.giapTuat;
      }
      case 11: {
        return Const.CanChi.atHoi;
      }
      case 12: {
        return Const.CanChi.binhTy;
      }
      default:
        break;
    }
  } else if (code === Const.Can.At.code || code === Const.Can.Canh.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.kySuu;
      }
      case 2: {
        return Const.CanChi.mauDan;
      }
      case 3: {
        return Const.CanChi.kyMao;
      }
      case 4: {
        return Const.CanChi.canhThin;
      }
      case 5: {
        return Const.CanChi.tanTi;
      }
      case 6: {
        return Const.CanChi.nhamNgo;
      }
      case 7: {
        return Const.CanChi.quiMui;
      }
      case 8: {
        return Const.CanChi.giapThan;
      }
      case 9: {
        return Const.CanChi.atDau;
      }
      case 10: {
        return Const.CanChi.binhTuat;
      }
      case 11: {
        return Const.CanChi.dinhHoi;
      }
      case 12: {
        return Const.CanChi.mauTy;
      }
      default:
        break;
    }
  } else if (code === Const.Can.Binh.code || code === Const.Can.Tan.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.tanSuu;
      }
      case 2: {
        return Const.CanChi.canhDan;
      }
      case 3: {
        return Const.CanChi.tanMao;
      }
      case 4: {
        return Const.CanChi.nhamThin;
      }
      case 5: {
        return Const.CanChi.quyTi;
      }
      case 6: {
        return Const.CanChi.giapNgo;
      }
      case 7: {
        return Const.CanChi.atMui;
      }
      case 8: {
        return Const.CanChi.binhThan;
      }
      case 9: {
        return Const.CanChi.dinhDau;
      }
      case 10: {
        return Const.CanChi.mauTuat;
      }
      case 11: {
        return Const.CanChi.kyHoi;
      }
      case 12: {
        return Const.CanChi.canhTy;
      }
      default:
        break;
    }
  } else if (code === Const.Can.Dinh.code || code === Const.Can.Nham.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.quiSuu;
      }
      case 2: {
        return Const.CanChi.nhamDan;
      }
      case 3: {
        return Const.CanChi.quiMao;
      }
      case 4: {
        return Const.CanChi.giapThin;
      }
      case 5: {
        return Const.CanChi.atTi;
      }
      case 6: {
        return Const.CanChi.binhNgo;
      }
      case 7: {
        return Const.CanChi.dinhMui;
      }
      case 8: {
        return Const.CanChi.mauThan;
      }
      case 9: {
        return Const.CanChi.kydau;
      }
      case 10: {
        return Const.CanChi.canhTuat;
      }
      case 11: {
        return Const.CanChi.tanHoi;
      }
      case 12: {
        return Const.CanChi.nhamTy;
      }
      default:
        break;
    }
  } else if (code === Const.Can.Mau.code || code === Const.Can.Qui.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.atSuu;
      }
      case 2: {
        return Const.CanChi.giapDan;
      }
      case 3: {
        return Const.CanChi.atMao;
      }
      case 4: {
        return Const.CanChi.binhThin;
      }
      case 5: {
        return Const.CanChi.dinhTi;
      }
      case 6: {
        return Const.CanChi.mauNgo;
      }
      case 7: {
        return Const.CanChi.kyMui;
      }
      case 8: {
        return Const.CanChi.canhThan;
      }
      case 9: {
        return Const.CanChi.tanDau;
      }
      case 10: {
        return Const.CanChi.nhamTuat;
      }
      case 11: {
        return Const.CanChi.quyHoi;
      }
      case 12: {
        return Const.CanChi.giapTy;
      }
      default:
        break;
    }
  }
}

export function TangCan(diaChi) {
  let result;

  switch (diaChi) {
    case "Mùi":
      return (result = [Const.Can.Ky, Const.Can.At, Const.Can.Dinh]);

    case "Thân":
      return (result = [Const.Can.Canh, Const.Can.Mau, Const.Can.Nham]);

    case "Dậu":
      return (result = [Const.Can.Tan]);
    case "Tuất":
      return (result = [Const.Can.Mau, Const.Can.Dinh, Const.Can.Tan]);
    case "Hợi":
      return (result = [Const.Can.Nham, {}, Const.Can.Giap]);
    case "Tý":
      return (result = [Const.Can.Qui]);
    case "Sửu":
      return (result = [Const.Can.Ky, Const.Can.Tan, Const.Can.Qui]);
    case "Dần":
      return (result = [Const.Can.Giap, Const.Can.Mau, Const.Can.Binh]);
    case "Mão":
      return (result = [Const.Can.At]);
    case "Thìn":
      return (result = [Const.Can.Mau, Const.Can.Qui, Const.Can.At]);
    case "Tỵ":
      return (result = [Const.Can.Binh, Const.Can.Canh, Const.Can.Mau]);
    case "Ngọ":
      return (result = [Const.Can.Dinh, {}, Const.Can.Ky]);
    default:
      break;
  }
}

export function nguHanh(thienCanNgay, thienCan) {
  if (thienCanNgay + " " + thienCan === "Giáp Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Giáp Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Giáp Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Giáp Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Giáp Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Giáp Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Giáp Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Giáp Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Giáp Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Giáp Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Ất Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Ất Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Ất Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Ất Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Ất Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Ất Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Ất Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Ất Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Ất Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Ất Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Bính Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Bính Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Bính Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Bính Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Bính Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Bính Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Bính Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Bính Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Bính Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Bính Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Đinh Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Đinh Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Đinh Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Đinh Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Đinh Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Đinh Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Đinh Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Đinh Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Đinh Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Đinh Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Mậu Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Mậu Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Mậu Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Mậu Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Mậu Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Mậu Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Mậu Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Mậu Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Mậu Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Mậu Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Kỷ Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Canh Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Canh Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Canh Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Canh Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Canh Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Canh Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Canh Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Canh Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Canh Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Canh Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Tân Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Tân Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Tân Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Tân Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Tân Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Tân Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Tân Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Tân Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Tân Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Tân Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Tân") {
    return "Âm Kim";
  } else if (thienCanNgay + " " + thienCan === "Nhâm Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Quý Quý") {
    return "Âm Thủy";
  } else if (thienCanNgay + " " + thienCan === "Quý Nhâm") {
    return "Dương Thủy";
  } else if (thienCanNgay + " " + thienCan === "Quý Giáp") {
    return "Dương Mộc";
  } else if (thienCanNgay + " " + thienCan === "Quý Ất") {
    return "Âm Mộc";
  } else if (thienCanNgay + " " + thienCan === "Quý Bính") {
    return "Dương Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Quý Đinh") {
    return "Âm Hỏa";
  } else if (thienCanNgay + " " + thienCan === "Quý Mậu") {
    return "Dương Thổ";
  } else if (thienCanNgay + " " + thienCan === "Quý Kỷ") {
    return "Âm Thổ";
  } else if (thienCanNgay + " " + thienCan === "Quý Canh") {
    return "Dương Kim";
  } else if (thienCanNgay + " " + thienCan === "Quý Tân") {
    return "Âm Kim";
  }
}




export const ThapThan = (thienCanDay, thienCan) => {
  let result;
  if (thienCanDay + " " + thienCan === "Kỷ Kỷ") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Kỷ Mậu") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Kỷ Bính") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Kỷ Đinh") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Kỷ Giáp") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Kỷ Ất") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Kỷ Nhâm") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Kỷ Quý") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Kỷ Canh") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Kỷ Tân") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Giáp Ất") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Giáp Giáp") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Giáp Quý") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Giáp Nhâm") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Giáp Tân") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Giáp Canh") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Giáp Kỷ") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Giáp Mậu") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Giáp Đinh") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Giáp Bính") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Ất Giáp") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Ất Ất") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Ất Nhâm") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Ất Quý") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Ất Canh") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Ất Tân") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Ất Mậu") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Ất Kỷ") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Ất Bính") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Ất Đinh") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Bính Bính") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Bính Đinh") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Bính Ất") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Bính Giáp") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Bính Quý") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Bính Nhâm") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Bính Tân") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Bính Canh") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Bính Kỷ") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Bính Mậu") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Đinh Đinh") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Đinh Bính") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Đinh Giáp") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Đinh Ất") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Đinh Nhâm") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Đinh Quý") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Đinh Canh") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Đinh Tân") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Đinh Mậu") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Đinh Kỷ") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Mậu Mậu") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Mậu Kỷ") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Mậu Đinh") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Mậu Bính") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Mậu Ất") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Mậu Giáp") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Mậu Quý") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Mậu Nhâm") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Mậu Tân") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Mậu Canh") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Canh Canh") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Canh Tân") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Canh Kỷ") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Canh Mậu") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Canh Đinh") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Canh Bính") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Canh Ất") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Canh Giáp") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Canh Quý") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Canh Nhâm") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Tân Tân") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Tân Canh") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Tân Mậu") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Tân Kỷ") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Tân Bính") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Tân Đinh") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Tân Giáp") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Tân Ất") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Tân Nhâm") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Tân Quý") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Nhâm Nhâm") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Nhâm Quý") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Nhâm Tân") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Nhâm Canh") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Nhâm Kỷ") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Nhâm Mậu") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Nhâm Đinh") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Nhâm Bính") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Nhâm Ất") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Nhâm Giáp") {
    result = Const.thapThan.ThucThan;
  } else if (thienCanDay + " " + thienCan === "Quý Quý") {
    result = Const.thapThan.TyKien;
  } else if (thienCanDay + " " + thienCan === "Quý Nhâm") {
    result = Const.thapThan.KiepTai;
  } else if (thienCanDay + " " + thienCan === "Quý Canh") {
    result = Const.thapThan.ChinhAn;
  } else if (thienCanDay + " " + thienCan === "Quý Tân") {
    result = Const.thapThan.ThienAn;
  } else if (thienCanDay + " " + thienCan === "Quý Mậu") {
    result = Const.thapThan.ChinhQuan;
  } else if (thienCanDay + " " + thienCan === "Quý Kỷ") {
    result = Const.thapThan.ThatSat;
  } else if (thienCanDay + " " + thienCan === "Quý Bính") {
    result = Const.thapThan.ChinhTai;
  } else if (thienCanDay + " " + thienCan === "Quý Đinh") {
    result = Const.thapThan.ThienTai;
  } else if (thienCanDay + " " + thienCan === "Quý Giáp") {
    result = Const.thapThan.ThuongQuan;
  } else if (thienCanDay + " " + thienCan === "Quý Ất") {
    result = Const.thapThan.ThucThan;
  }

  return result;
};

export const DongTayTuTrach = (namTietKhi) => {
  let result;
  if (
    namTietKhi === 0 ||
    namTietKhi === 1 ||
    namTietKhi === 3 ||
    namTietKhi === 4 ||
    namTietKhi === 9
  ) {
    return (result = [Const.DongTuMenh]);
  } else if (
    namTietKhi === 2 ||
    namTietKhi === 5 ||
    namTietKhi === 6 ||
    namTietKhi === 7 ||
    namTietKhi === 8
  ) {
    return (result = [Const.TayTuMenh]);
  }
};

export const KhongVong = (ThienCanDiaChiNgay) => {
  let result;

  switch (ThienCanDiaChiNgay) {
    case "Giáp Tý":
      return (result = ["Tuất,", " Hợi"]);

    case "Giáp Dần":
      return (result = ["Tý,", " Sửu"]);

    case "Giáp Thìn":
      return (result = ["Dần,", " Mão"]);

    case "Giáp Ngọ":
      return (result = ["Thìn,", " Tỵ"]);

    case "Giáp Thân":
      return (result = [" Ngọ,", " Mùi"]);

    case "Giáp Tuất":
      return (result = [" Thân,", " Dậu"]);

    case "Ất Sửu":
      return (result = ["Tuất,", " Hợi"]);

    case "Ất Mão":
      return (result = ["Tý,", " Sửu"]);

    case "Ất Tỵ":
      return (result = ["Dần,", " Mão"]);

    case "Ất Mùi":
      return (result = ["Thìn,", " Tỵ"]);

    case "Ất Dậu":
      return (result = ["Ngọ,", " Mùi"]);

    case "Ất Hợi":
      return (result = [" Thân,", " Dậu"]);
    case "Bính Tý":
      return (result = [" Thân,", " Dậu"]);

    case "Bính Dần":
      return (result = ["Tuất,", " Hợi"]);

    case "Bính Thìn":
      return (result = ["Tý,", " Sửu"]);

    case "Bính Ngọ":
      return (result = ["Dần,", " Mão"]);

    case "Bính Thân":
      return (result = ["Thìn,", " Tỵ"]);

    case "Bính Tuất":
      return (result = ["Ngọ,", " Mùi"]);

    case "Đinh Sửu":
      return (result = ["Thân,", " Dậu"]);

    case "Đinh Mão":
      return (result = ["Tuất,", " Hợi"]);

    case "Đinh Tỵ":
      return (result = ["Tý,", " Sửu"]);

    case "Đinh Mùi":
      return (result = ["Dần,", " Mão"]);

    case "Đinh Dậu":
      return (result = [" Thìn,", " Tỵ"]);

    case "Đinh Hợi":
      return (result = [" Ngọ,", " Mùi"]);

    case "Mậu Tý":
      return (result = [" Ngọ,", " Mùi"]);

    case "Mậu Dần":
      return (result = [" Thân,", " Dậu"]);

    case "Mậu Thìn":
      return (result = ["Tuất,", " Hợi"]);

    case "Mậu Ngọ":
      return (result = [" Tý,", " Sửu"]);

    case "Mậu Thân":
      return (result = [" Dần,", " Mão"]);

    case "Mậu Tuất":
      return (result = [" Thìn,", " Tỵ"]);

    case "Kỷ Sửu":
      return (result = ["Ngọ,", " Mùi"]);

    case "Kỷ Mão":
      return (result = ["Thân,", " Dậu"]);

    case "Kỷ Tỵ":
      return (result = ["Tuất,", " Hợi"]);

    case "Kỷ Mùi":
      return (result = ["Tý,", " Sửu"]);

    case "Kỷ Dậu":
      return (result = [" Dần,", " Mão"]);

    case "Kỷ Hợi":
      return (result = [" Thìn,", " Tỵ"]);

    case "Canh Tý":
      return (result = [" Thìn,", " Tỵ"]);

    case "Canh Dần":
      return (result = [" Ngọ,", " Mùi"]);

    case "Canh Thìn":
      return (result = [" Thân,", " Dậu"]);

    case "Canh Ngọ":
      return (result = [" Tuất,", " Hợi"]);

    case "Canh Thân":
      return (result = [" Tý,", " Sửu"]);

    case "Canh Tuất":
      return (result = [" Dần,", " Mão"]);

    case "Tân Sửu":
      return (result = [" Thìn,", " Tỵ"]);

    case "Tân Mão":
      return (result = [" Ngọ,", " Mùi"]);

    case "Tân Tỵ":
      return (result = [" Thân,", " Dậu"]);

    case "Tân Mùi":
      return (result = [" Tuất,", " Hợi"]);

    case "Tân Dậu":
      return (result = [" Tý,", " Sửu"]);

    case "Tân Hợi":
      return (result = [" Dần,", " Mão"]);
    case "Nhâm Tý":
      return (result = ["Dần,", " Mão"]);

    case "Nhâm Dần":
      return (result = ["Thìn,", "Tỵ"]);

    case "Nhâm Thìn":
      return (result = ["Ngọ,", " Mùi"]);

    case "Nhâm Ngọ":
      return (result = ["Thân,", " Dậu"]);

    case "Nhâm Thân":
      return (result = ["Tuất, ", "Hợi"]);

    case "Nhâm Tuất":
      return (result = ["Tý,", " Sửu"]);

    case "Quý Sửu":
      return (result = ["Dần,", " Mão"]);
    case "Quý Mão":
      return (result = ["Thìn,", " Tỵ"]);
    case "Quý Tỵ":
      return (result = ["Ngọ,", ",Mùi"]);
    case "Quý Mùi":
      return (result = ["Thân,", " Dậu"]);
    case "Quý Dậu":
      return (result = ["Tuất,", "Hợi"]);
    case "Quý Hợi":
      return (result = ["Tý,", " Sửu"]);

    default:
      return (result = "");
  }
};

export const ThaiTuc = (ThienCanDiaChiNgay) => {
  let result;

  switch (ThienCanDiaChiNgay) {
    case "Giáp Tý":
      return (result = "Kỷ Sửu");

    case "Giáp Dần":
      return (result = "Kỷ Hợi");

    case "Giáp Thìn":
      return (result = "Kỷ Dậu");

    case "Giáp Ngọ":
      return (result = "Kỷ Mùi");

    case "Giáp Thân":
      return (result = "Kỷ Tỵ");

    case "Giáp Tuất":
      return (result = "Kỷ Mão");

    case "Ất Sửu":
      return (result = "Canh Tý");

    case "Ất Mão":
      return (result = "Canh Tuất");

    case "Ất Tỵ":
      return (result = "Canh Thân");

    case "Ất Mùi":
      return (result = "Canh Ngọ");

    case "Ất Dậu":
      return (result = "Canh Thìn");

    case "Ất Hợi":
      return (result = "Canh Dần");
    case "Bính Tý":
      return (result = "Tân Sửu");

    case "Bính Dần":
      return (result = "Tân Hợi");

    case "Bính Thìn":
      return (result = "Tân Dậu");

    case "Bính Ngọ":
      return (result = "Tân Mùi");

    case "Bính Thân":
      return (result = "Tân Tỵ");

    case "Bính Tuất":
      return (result = "Tân Mão");

    case "Đinh Sửu":
      return (result = "Nhâm Tý");

    case "Đinh Mão":
      return (result = "Nhâm Tuất");

    case "Đinh Tỵ":
      return (result = "Nhâm Thân");

    case "Đinh Mùi":
      return (result = "Nhâm Ngọ");

    case "Đinh Dậu":
      return (result = "Nhâm Thìn");

    case "Đinh Hợi":
      return (result = "Nhâm Dần");

    case "Mậu Tý":
      return (result = "Quý Sửu");

    case "Mậu Dần":
      return (result = "Quý Hợi");

    case "Mậu Thìn":
      return (result = "Quý Dậu");

    case "Mậu Ngọ":
      return (result = "Quý Mùi");

    case "Mậu Thân":
      return (result = "Quý Tỵ");

    case "Mậu Tuất":
      return (result = "Quý Mão");

    case "Kỷ Sửu":
      return (result = "Giáp Tý");

    case "Kỷ Mão":
      return (result = "Giáp Tuất");

    case "Kỷ Tỵ":
      return (result = "Giáp Thân");

    case "Kỷ Mùi":
      return (result = "Giáp Ngọ");

    case "Kỷ Dậu":
      return (result = "Giáp Thìn");

    case "Kỷ Hợi":
      return (result = "Giáp Dần");

    case "Canh Tý":
      return (result = "Ất Sửu");

    case "Canh Dần":
      return (result = "Ất Hợi");

    case "Canh Thìn":
      return (result = "Ất Dậu");

    case "Canh Ngọ":
      return (result = "Ất Mùi");

    case "Canh Thân":
      return (result = "Ất Tỵ");

    case "Canh Tuất":
      return (result = "Ất Mão");

    case "Tân Sửu":
      return (result = "Bính Tý");

    case "Tân Mão":
      return (result = "Bính Tuất");

    case "Tân Tỵ":
      return (result = "Bính Thân");

    case "Tân Mùi":
      return (result = "Bính Ngọ");

    case "Tân Dậu":
      return (result = "Bính Thìn");

    case "Tân Hợi":
      return (result = "Bính Dần");
    case "Nhâm Tý":
      return (result = "Đinh Sửu");

    case "Nhâm Dần":
      return (result = "Đinh Hợi");

    case "Nhâm Thìn":
      return (result = "Đinh Dậu");

    case "Nhâm Ngọ":
      return (result = "Định Mùi");

    case "Nhâm Thân":
      return (result = "Đinh Tỵ");

    case "Nhâm Tuất":
      return (result = "Đinh Mão");

    case "Quý Sửu":
      return (result = "Mậu Tý");
    case "Quý Mão":
      return (result = "Mậu Tuất");
    case "Quý Tỵ":
      return (result = "Mậu Thân");
    case "Quý Mùi":
      return (result = "Mậu Ngọ");
    case "Quý Dậu":
      return (result = "Mậu Thìn");
    case "Quý Hợi":
      return (result = "Mậu Dần");

    default:
      return (result = "");
  }
};

export const TruGio = (ThienCanHour, hour, minutes) => {

  hour = hour !== 'null' ? +hour : 0
  minutes = minutes ? +minutes : 0

  if (hour === 2 || hour === 4 || hour === 6 || hour === 8 || hour === 10 || hour === 12 || hour === 14 || hour === 16 || hour === 18 || hour === 20 || hour === 22) {
    minutes += 60
  }

  if (ThienCanHour === "Giáp" || ThienCanHour === "Kỷ") {

    if (minutes >= 0 && minutes <= 10) {
      return "Giáp Tý"
    }
    if (minutes >= 11 && minutes <= 20) {
      return "Ất Sửu"
    }
    if (minutes >= 21 && minutes <= 30) {
      return "Bính Dần"
    }
    if (minutes >= 31 && minutes <= 40) {
      return "Đinh Mão"
    }
    if (minutes >= 41 && minutes <= 50) {
      return "Mậu Thìn"
    }
    if (minutes >= 51 && minutes <= 60) {
      return "Kỷ Tỵ"
    }

    if (minutes >= 61 && minutes <= 70) {
      return "Canh Ngọ"
    }
    if (minutes >= 71 && minutes <= 80) {
      return "Tân Mùi"
    }
    if (minutes >= 81 && minutes <= 90) {
      return "Nhâm Thân"
    }
    if (minutes >= 91 && minutes <= 100) {
      return "Quý Dậu"
    }
    if (minutes >= 101 && minutes <= 110) {
      return "Giáp Tuất"
    }
    if (minutes >= 111 && minutes <= 120) {
      return "Ất Hợi"
    }


  }
  if (ThienCanHour === "Ất" || ThienCanHour === "Canh") {

    if (minutes >= 0 && minutes <= 10) {
      return "Bính Tý"
    }
    if (minutes >= 11 && minutes <= 20) {
      return "Đinh Sửu"
    }
    if (minutes >= 21 && minutes <= 30) {
      return "Mậu Dần"
    }
    if (minutes >= 31 && minutes <= 40) {
      return "Kỷ Mão"
    }
    if (minutes >= 41 && minutes <= 50) {
      return "Canh Thìn"
    }
    if (minutes >= 51 && minutes <= 60) {
      return "Tân Tỵ"
    }

    if (minutes >= 61 && minutes <= 70) {
      return "Nhâm Ngọ"
    }
    if (minutes >= 71 && minutes <= 80) {
      return "Quý Mùi"
    }
    if (minutes >= 81 && minutes <= 90) {
      return "Giáp Thân"
    }
    if (minutes >= 91 && minutes <= 100) {
      return "Ất Dậu"
    }
    if (minutes >= 101 && minutes <= 110) {
      return "Bính Tuất"
    }
    if (minutes >= 111 && minutes <= 120) {
      return "Đinh Hợi"
    }


  }
  if (ThienCanHour === "Bính" || ThienCanHour === "Tân") {

    if (minutes >= 0 && minutes <= 10) {
      return "Mậu Tý"
    }
    if (minutes >= 11 && minutes <= 20) {
      return "Kỷ Sửu"
    }
    if (minutes >= 21 && minutes <= 30) {
      return "Canh Dần"
    }
    if (minutes >= 31 && minutes <= 40) {
      return "Tân Mão"
    }
    if (minutes >= 41 && minutes <= 50) {
      return "Nhâm Thìn"
    }
    if (minutes >= 51 && minutes <= 60) {
      return "Quý Tỵ"
    }

    if (minutes >= 61 && minutes <= 70) {
      return "Giáp Ngọ"
    }
    if (minutes >= 71 && minutes <= 80) {
      return "Ất Mùi"
    }
    if (minutes >= 81 && minutes <= 90) {
      return "Bính Thân"
    }
    if (minutes >= 91 && minutes <= 100) {
      return "Đinh Dậu"
    }
    if (minutes >= 101 && minutes <= 110) {
      return "Mậu Tuất"
    }
    if (minutes >= 111 && minutes <= 120) {
      return "Kỷ Hợi"
    }


  }
  if (ThienCanHour === "Đinh" || ThienCanHour === "Nhâm") {

    if (minutes >= 0 && minutes <= 10) {
      return "Canh Tý"
    }
    if (minutes >= 11 && minutes <= 20) {
      return "Tân Sửu"
    }
    if (minutes >= 21 && minutes <= 30) {
      return "Nhâm Dần"
    }
    if (minutes >= 31 && minutes <= 40) {
      return "Quý Mão"
    }
    if (minutes >= 41 && minutes <= 50) {
      return "Giáp Thìn"
    }
    if (minutes >= 51 && minutes <= 60) {
      return "Ất Tỵ"
    }

    if (minutes >= 61 && minutes <= 70) {
      return "Bính Ngọ"
    }
    if (minutes >= 71 && minutes <= 80) {
      return "Đinh Mùi"
    }
    if (minutes >= 81 && minutes <= 90) {
      return "Mậu Thân"
    }
    if (minutes >= 91 && minutes <= 100) {
      return "Kỷ Dậu"
    }
    if (minutes >= 101 && minutes <= 110) {
      return "Canh Tuất"
    }
    if (minutes >= 111 && minutes <= 120) {
      return "Tân Hợi"
    }


  }
  if (ThienCanHour === "Mậu" || ThienCanHour === "Quý") {

    if (minutes >= 0 && minutes <= 10) {
      return "Nhâm Tý"
    }
    if (minutes >= 11 && minutes <= 20) {
      return "Quý Sửu"
    }
    if (minutes >= 21 && minutes <= 30) {
      return "Giáp Dần"
    }
    if (minutes >= 31 && minutes <= 40) {
      return "Ất Mão"
    }
    if (minutes >= 41 && minutes <= 50) {
      return "Bính Thìn"
    }
    if (minutes >= 51 && minutes <= 60) {
      return "Đinh Tỵ"
    }

    if (minutes >= 61 && minutes <= 70) {
      return "Mậu Ngọ"
    }
    if (minutes >= 71 && minutes <= 80) {
      return "Kỷ Mùi"
    }
    if (minutes >= 81 && minutes <= 90) {
      return "Canh Thân"
    }
    if (minutes >= 91 && minutes <= 100) {
      return "Tân Dậu"
    }
    if (minutes >= 101 && minutes <= 110) {
      return "Nhâm Tuất"
    }
    if (minutes >= 111 && minutes <= 120) {
      return "Quý Hợi"
    }


  }
  return ""
}

export const KhongVong2 = (ThienCanDiaChiNgay, laSo) => {
  let result = "";
  // tuần giáp thìn

  if (ThienCanDiaChiNgay === "Canh Tuất" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Thìn" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Thìn" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Tuất" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Tỵ" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Tỵ" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Ngọ" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Ngọ" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Mùi" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Mùi" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Thân" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Thân" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Dậu" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Dậu" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Hợi" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Hợi" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Tý" && laSo === "Dần") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Tý" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Sửu" && laSo === "Mão") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Sửu" && laSo === "Dần") {
    return (result = "Không Vong");
  }
  // tuần giáp tý
  else if (ThienCanDiaChiNgay === "Giáp Tý" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Tý" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Sửu" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Sửu" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Dần" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Dần" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Mão" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Mão" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Thìn" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Thìn" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Tỵ" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Tỵ" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Ngọ" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Ngọ" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Mùi" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Mùi" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Thân" && laSo === "Tuất") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Thân" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Dậu" && laSo === "Hợi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Dậu" && laSo === "Tuất") {
    return (result = "Không Vong");
  }
  // tuần giáp tuất
  else if (ThienCanDiaChiNgay === "Giáp Tuát" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Tuát" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Hợi" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Hợi" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Tý" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Tý" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Sửu" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Sửu" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Dần" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Dần" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Mão" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Mão" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Thìn" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Thìn" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Tỵ" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Tỵ" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Ngọ" && laSo === "Thân") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Ngọ" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Mùi" && laSo === "Dậu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Mùi" && laSo === "Thân") {
    return (result = "Không Vong");
  }
  // tuần giáp thân
  else if (ThienCanDiaChiNgay === "Giáp Thân" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Thân" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Dậu" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Dậu" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Tuất" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Tuất" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Hợi" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Hợi" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Tý" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Tý" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Sửu" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Sửu" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Dần" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Dần" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Mão" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Mão" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Thìn" && laSo === "Ngọ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Thìn" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Tỵ" && laSo === "Mùi") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Tỵ" && laSo === "Ngọ") {
    return (result = "Không Vong");
  }
  // tuan giap ngo
  else if (ThienCanDiaChiNgay === "Giáp Ngọ" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Ngọ" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Mùi" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Mùi" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Thân" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Thân" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Dậu" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Dậu" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Tuất" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Tuất" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Hợi" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Hợi" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Tý" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Tý" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Sửu" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Sửu" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Dần" && laSo === "Thìn") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Dần" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Mão" && laSo === "Tỵ") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Mão" && laSo === "Thìn") {
    return (result = "Không Vong");
  }
  // tuan giap dan
  else if (ThienCanDiaChiNgay === "Giáp Dần" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Giáp Dần" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Mão" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Ất Mão" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Thìn" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Bính Thìn" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Tỵ" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Đinh Tỵ" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Ngọ" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Mậu Ngọ" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Mùi" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Kỷ Mùi" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Thân" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Canh Thân" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Dậu" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Tân Dậu" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Tuất" && laSo === "Tý") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Nhâm Tuất" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Hợi" && laSo === "Sửu") {
    return (result = "Không Vong");
  } else if (ThienCanDiaChiNgay === "Quý Hợi" && laSo === "Tý") {
    return (result = "Không Vong");
  }
  return result;
};
export const handleYearTietKhi = (year, gender) => {
  const yearConvert = year.toString().split("");

  const sumYear = parseInt(yearConvert[yearConvert.length - 2]) + parseInt(yearConvert[yearConvert.length - 1]);
  if (sumYear === 0) return sumYear;
  const result = sumYear.toString().split("");

  if (result.length > 1) {
    const soVuaTimDuoc = parseInt(result[0]) + parseInt(result[1]);

    if (gender === "Nam") {
      if (year < 2000) {
        const data = 10 - soVuaTimDuoc;

        return data;
      } else if (year >= 2000) {
        const data = 9 - soVuaTimDuoc;

        return data;
      }
    } else if (gender === "Nữ") {
      if (year < 2000) {
        const data = 5 + soVuaTimDuoc;

        const checkData = data.toString().split("");

        if (checkData.length > 1) {
          return parseInt(checkData[0]) + parseInt(checkData[1]);
        } else if (checkData.length === 1) {
          return data;
        }
      } else if (year >= 2000) {
        const data = 6 + soVuaTimDuoc;

        const checkData = data.toString().split("");

        if (checkData.length > 1) {
          return parseInt(checkData[0]) + parseInt(checkData[1]);
        } else if (checkData.length === 1) {
          return data;
        }
      }
    }
  } else if (result.length === 1) {
    if (gender === "Nam") {
      if (year < 2000) {
        const data = 10 - sumYear;

        return data;
      } else if (year >= 2000) {
        const data = 9 - sumYear;
        return data;
      }
    } else if (gender === "Nữ") {
      if (year < 2000) {
        const data = 5 + sumYear;

        const checkData = data.toString().split("");

        if (checkData.length > 1) {
          return parseInt(checkData[0]) + parseInt(checkData[1]);
        } else if (checkData.length === 1) {
          return data;
        }
      } else if (year >= 2000) {
        const data = 6 + sumYear;

        const checkData = data.toString().split("");

        if (checkData.length > 1) {
          return parseInt(checkData[0]) + parseInt(checkData[1]);
        } else if (checkData.length === 1) {
          return data;
        }
      }
    }
  }
};

export const ThanSatDiaChi = (diaChiNgay, key) => {
  let result = "";
  if (diaChiNgay === "Tý") {
    if (key === "Tý") {
      return (result = "Tướng Tinh");
    } else if (key === "Dần") {
      return (result = `Dịch Mã, Cô Thần, Cách Giác, Tang Môn`);
    } else if (key === "Dậu") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Tuất") {
      return (result = "Quả Tú, Huyết Nhẫn, Thiên Cấu ");
    } else if (key === "Thìn") {
      return (result = "Hoa Cái");
    } else if (key === "Hợi") {
      return (result = "Vong Thần");
    } else if (key === "Tỵ") {
      return (result = "Kiếp Sát");
    }
  } else if (diaChiNgay === "Sửu") {
    if (key === "Dậu") {
      return (result = "Tướng Tinh, Huyết Nhẫn ");
    } else if (key === "Hợi") {
      return (result = "Dịch Mã, Thiên Cấu ");
    } else if (key === "Ngọ") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Dần") {
      return (result = "Cô Thần, Kiếp Sát ");
    } else if (key === "Tuất") {
      return (result = "Quả Tú ");
    } else if (key === "Sửu") {
      return (result = "Hoa Cái ");
    } else if (key === "Thân") {
      return (result = "Vong Thần ");
    } else if (key === "Mão") {
      return (result = "Cách Giác, Tang Môn ");
    }
  } else if (diaChiNgay === "Dần") {
    if (key === "Tỵ") {
      return (result = "Cô Thần, Vong Thần ");
    } else if (key === "Hợi") {
      return (result = "Kiếp Sát ");
    } else if (key === "Ngọ") {
      return (result = "Tướng Tinh ");
    } else if (key === "Thìn") {
      return (result = "Cách Giác, Tang Môn ");
    } else if (key === "Tuất") {
      return (result = "Hoa Cái ");
    } else if (key === "Sửu") {
      return (result = "Quả Tú");
    } else if (key === "Thân") {
      return (result = "Dịch Mã, Huyết Nhẫn ");
    } else if (key === "Mão") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Tý") {
      return (result = "Thiên Cấu ");
    }
  } else if (diaChiNgay === "Mão") {
    if (key === "Mão") {
      return (result = "Tướng Tinh ");
    } else if (key === "Tỵ") {
      return (result = "Dịch Mã, Cô Thần, Cách Giao, Tang Môn ");
    } else if (key === "Tý") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Sửu") {
      return (result = "Quả Tú, Thiên Cấu ");
    } else if (key === "Mùi") {
      return (result = "Hoa Cái, Huyết Nhẫn ");
    } else if (key === "Dần") {
      return (result = "Vong Thần ");
    } else if (key === "Thân") {
      return (result = "Kiếp Sát ");
    }
  } else if (diaChiNgay === "Thìn") {
    if (key === "Tý") {
      return (result = "Tướng Tinh ");
    } else if (key === "Dần") {
      return (result = "Dịch Mã, Thiên Cấu ");
    } else if (key === "Dậu") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Tỵ") {
      return (result = "Cô Thần, Kiếp Sát ");
    } else if (key === "Sửu") {
      return (result = "Quả Tú ");
    } else if (key === "Thìn") {
      return (result = "Hoa Cái ");
    } else if (key === "Hợi") {
      return (result = "Vong Thần ");
    } else if (key === "Ngọ") {
      return (result = "Huyết Nhẫn, Cách Giác, Tang Môn ");
    }
  } else if (diaChiNgay === "Tỵ") {
    if (key === "Dậu") {
      return (result = "Tướng Tinh ");
    } else if (key === "Hợi") {
      return (result = "Dịch Mã ");
    } else if (key === "Ngọ") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Thân") {
      return (result = "Cô Thần, Vong Thần ");
    } else if (key === "Thìn") {
      return (result = "Quả Tú ");
    } else if (key === "Sửu") {
      return (result = "Hoa Cái ");
    } else if (key === "Tỵ") {
      return (result = "Huyết Nhẫn ");
    } else if (key === "Dần") {
      return (result = "Kiếp Sát ");
    } else if (key === "Mùi") {
      return (result = "Cách Giác, Tang Môn ");
    } else if (key === "Mão") {
      return (result = "Thiên Cấu ");
    }
  } else if (diaChiNgay === "Ngọ") {
    if (key === "Ngọ") {
      return (result = "Tướng Tinh ");
    } else if (key === "Thân") {
      return (result = "Dịch Mã, Cô Thần ");
    } else if (key === "Mão") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Thân") {
      return (result = "Cô Thần, Vong Thần, Cách Giác, Tang Môn ");
    } else if (key === "Thìn") {
      return (result = "Quả Tú, Huyết Nhẫn, Thiên Cấu ");
    } else if (key === "Tuất") {
      return (result = "Hoa Cái ");
    } else if (key === "Tỵ") {
      return (result = "Vong Thần ");
    } else if (key === "Hợi") {
      return (result = "Kiếp Sát ");
    }
  } else if (diaChiNgay === "Mùi") {
    if (key === "Mão") {
      return (result = "Tướng Tinh ");
    } else if (key === "Tỵ") {
      return (result = "Dịch Mã, Thiên Cấu ");
    } else if (key === "Tý") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Thân") {
      return (result = "Cô Thần, Kiếp Sát ");
    } else if (key === "Thìn") {
      return (result = "Quả Tú ");
    } else if (key === "Mùi") {
      return (result = "Hoa Cái ");
    } else if (key === "Dần") {
      return (result = "Vong Thần ");
    } else if (key === "Mão") {
      return (result = "Huyết Nhẫn ");
    } else if (key === "Dậu") {
      return (result = "Cách Giác, Tang Môn ");
    }
  } else if (diaChiNgay === "Thân") {
    if (key === "Tý") {
      return (result = "Tướng Tinh ");
    } else if (key === "Dần") {
      return (result = "Dịch Mã, Huyết Nhẫn ");
    } else if (key === "Dậu") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Hợi") {
      return (result = "Cô Thần, Vong Thần ");
    } else if (key === "Mùi") {
      return (result = "Quả Tú ");
    } else if (key === "Thìn") {
      return (result = "Hoa Cái ");
    } else if (key === "Tỵ") {
      return (result = "Kiếp Sát ");
    } else if (key === "Tuất") {
      return (result = "Cách Giác, Tang Môn ");
    } else if (key === "Ngọ") {
      return (result = "Thiên Cấu ");
    }
  } else if (diaChiNgay === "Dậu") {
    if (key === "Dậu") {
      return (result = "Tướng Tinh ");
    } else if (key === "Hợi") {
      return (result = `Dịch Mã, Cô Thần, Cách Giác, Tang Môn`);
    } else if (key === "Ngọ") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Mùi") {
      return (result = "Quả Tú, Thiên Cấu ");
    } else if (key === "Sửu") {
      return (result = "Hoa Cái ");
    } else if (key === "Thân") {
      return (result = "Vong Thần ");
    } else if (key === "Sửu") {
      return (result = "Huyết Nhẫn ");
    } else if (key === "Dần") {
      return (result = "Kiếp Sát ");
    }
  } else if (diaChiNgay === "Tuất") {
    if (key === "Ngọ") {
      return (result = "Tướng Tinh ");
    } else if (key === "Thân") {
      return (result = "Dịch Mã, Thiên Cấu ");
    } else if (key === "Mão") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Hợi") {
      return (result = "Cô Thần, Kiếp Sát ");
    } else if (key === "Mùi") {
      return (result = "Quả Tú ");
    } else if (key === "Tuất") {
      return (result = "Hoa Cái ");
    } else if (key === "Tỵ") {
      return (result = "Vong Thần ");
    } else if (key === "Tý") {
      return (result = "Huyết Nhẫn, Cách Giác, Tang Môn ");
    }
  } else if (diaChiNgay === "Hợi") {
    if (key === "Mão") {
      return (result = "Tướng Tinh ");
    } else if (key === "Tỵ") {
      return (result = "Dịch Mã ");
    } else if (key === "Tý") {
      return (result = "Hàm Trì Đào Hoa ");
    } else if (key === "Dần") {
      return (result = "Cô Thần ");
    } else if (key === "Tuất") {
      return (result = "Quả Tú ");
    } else if (key === "Mùi") {
      return (result = "Hoa Cái ");
    } else if (key === "Dần") {
      return (result = "Vong Thần ");
    } else if (key === "Hợi") {
      return (result = "Huyết Nhẫn ");
    } else if (key === "Thân") {
      return (result = "Kiếp Sát ");
    } else if (key === "Sửu") {
      return (result = "Cách Giác, Tang Môn ");
    } else if (key === "Dậu") {
      return (result = "Thiên Cầu ");
    }
  }
  return result;
};

export const ThienXa = (diaChiThang, thienCanNgay, diaChiNgay) => {
  let result = "";
  if (diaChiThang === "Dần" && thienCanNgay + " " + diaChiNgay === "Mậu Dần") {
    return (result = "Thiên Xá");
  } else if (
    diaChiThang === "Mão" &&
    thienCanNgay + " " + diaChiNgay === "Mậu Dần"
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Tỵ", thienCanNgay + " " + diaChiNgay === "Giáp Ngọ")
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Ngọ", thienCanNgay + " " + diaChiNgay === "Giáp Ngọ")
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Thân", thienCanNgay + " " + diaChiNgay === "Mậu Thân")
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Dậu", thienCanNgay + " " + diaChiNgay === "Mậu Thân")
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Hợi", thienCanNgay + " " + diaChiNgay === "Giáp Tý")
  ) {
    return (result = "Thiên Xá");
  } else if (
    (diaChiThang === "Tý", thienCanNgay + " " + diaChiNgay === "Giáp Tý")
  ) {
    return (result = "Thiên Xá");
  } else if (diaChiThang === "Dần" && diaChiNgay === "Sửu") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Mão" && diaChiNgay === "Dần") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Thìn" && diaChiNgay === "Mão") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Tỵ" && diaChiNgay === "Thìn") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Ngọ" && diaChiNgay === "Tỵ") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Mùi" && diaChiNgay === "Ngọ") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Thân" && diaChiNgay === "Mùi") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Dậu" && diaChiNgay === "Thân") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Tuất" && diaChiNgay === "Dậu") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Hợi" && diaChiNgay === "Tuất") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Tý" && diaChiNgay === "Hợi") {
    return (result = "Thiên Y");
  } else if (diaChiThang === "Sửu" && diaChiNgay === "Tý") {
    return (result = "Thiên Y");
  }
  return result;
};

export const NguyetChi = (diaChi) => {
  let result = "";
  if (diaChi === "Dần") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Mão") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Thìn") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Tỵ") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Ngọ") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Mùi") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Thân") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Dậu") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Tuất") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Hợi") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Tý") {
    return (result = "Nguyệt Chi");
  } else if (diaChi === "Sửu") {
    return (result = "Nguyệt Chi");
  }
  return result;
};

export const ThienY = (diaChi) => {
  let result = "";
  if (diaChi === "Dần") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Mão") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Thìn") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Tỵ") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Ngọ") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Mùi") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Thân") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Dậu") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Tuất") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Hợi") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Tý") {
    return (result = ", Thiên Y");
  } else if (diaChi === "Sửu") {
    return (result = ", Thiên Y");
  }
  return result;
};

export const KhoiCuong = (thienCanNgay, diaChiNgay) => {
  let result = "";
  if (thienCanNgay + " " + diaChiNgay === "Canh Thìn") {
    return (result = "Khôi Cương");
  } else if (thienCanNgay + " " + diaChiNgay === "Canh Tuất") {
    return (result = "Khôi Cương");
  } else if (thienCanNgay + " " + diaChiNgay === "Nhâm Thìn") {
    return (result = "Khôi Cương");
  } else if (thienCanNgay + " " + diaChiNgay === "Nhâm Tuất") {
    return (result = "Khôi Cương");
  }
  return result;
};

export const ThanSatDiaChiArr = (diaChiNgay) => {
  let result = "";
  if (diaChiNgay === "Tý") {
    return (result = [
      { name: "Tướng Tinh", giap: "Tý" },
      { name: "Đào Hoa", giap: "Dậu" },
      { name: "Cô Thần", giap: "Dần" },
      { name: "Kiếp Sát", giap: "Tỵ" },
      { name: "Dịch Mã", giap: "Dần" },
      { name: "Quả Tú", giap: "Tuất" },
    ]);
  } else if (diaChiNgay === "Sửu") {
    return (result = [
      { name: "Tướng Tinh", giap: "Dậu" },
      { name: "Đào Hoa", giap: "Ngọ" },
      { name: "Cô Thần", giap: "Dần" },
      { name: "Kiếp Sát", giap: "Dần" },
      { name: "Dịch Mã", giap: "Hợi" },
      { name: "Quả Tú", giap: "Tuất" },
    ]);
  } else if (diaChiNgay === "Dần") {
    return (result = [
      { name: "Tướng Tinh", giap: "Ngọ" },
      { name: "Đào Hoa", giap: "Mão" },
      { name: "Cô Thần", giap: "Tỵ" },
      { name: "Kiếp Sát", giap: "Hợi" },
      { name: "Dịch Mã", giap: "Thân" },
      { name: "Quả Tú", giap: "Sửu" },
    ]);
  } else if (diaChiNgay === "Mão") {
    return (result = [
      { name: "Tướng Tinh", giap: "Mão" },
      { name: "Đào Hoa", giap: "Tý" },
      { name: "Cô Thần", giap: "Tỵ" },
      { name: "Kiếp Sát", giap: "Thân" },
      { name: "Dịch Mã", giap: "Tỵ" },
      { name: "Quả Tú", giap: "Sửu" },
    ]);
  } else if (diaChiNgay === "Thìn") {
    return (result = [
      { name: "Tướng Tinh", giap: "Tý" },
      { name: "Đào Hoa", giap: "Dậu" },
      { name: "Cô Thần", giap: "Tỵ" },
      { name: "Kiếp Sát", giap: "Tỵ" },
      { name: "Dịch Mã", giap: "Dần" },
      { name: "Quả Tú", giap: "Sửu" },
    ]);
  } else if (diaChiNgay === "Tỵ") {
    return (result = [
      { name: "Tướng Tinh", giap: "Dậu" },
      { name: "Đào Hoa", giap: "Ngọ" },
      { name: "Cô Thần", giap: "Thân" },
      { name: "Kiếp Sát", giap: "Dần" },
      { name: "Dịch Mã", giap: "Hợi" },
      { name: "Quả Tú", giap: "Thìn" },
    ]);
  } else if (diaChiNgay === "Ngọ") {
    return (result = [
      { name: "Tướng Tinh", giap: "Ngọ" },
      { name: "Đào Hoa", giap: "Mão" },
      { name: "Cô Thần", giap: "Thân" },
      { name: "Kiếp Sát", giap: "Hợi" },
      { name: "Dịch Mã", giap: "Thân" },
      { name: "Quả Tú", giap: "Thìn" },
    ]);
  } else if (diaChiNgay === "Mùi") {
    return (result = [
      { name: "Tướng Tinh", giap: "Mão" },
      { name: "Đào Hoa", giap: "Tý" },
      { name: "Cô Thần", giap: "Thân" },
      { name: "Kiếp Sát", giap: "Thân" },
      { name: "Dịch Mã", giap: "Tỵ" },
      { name: "Quả Tú", giap: "Thìn" },
    ]);
  } else if (diaChiNgay === "Thân") {
    return (result = [
      { name: "Tướng Tinh", giap: "Tý" },
      { name: "Đào Hoa", giap: "Dậu" },
      { name: "Cô Thần", giap: "Hợi" },
      { name: "Kiếp Sát", giap: "Tỵ" },
      { name: "Dịch Mã", giap: "Dần" },
      { name: "Quả Tú", giap: "Mùi" },
    ]);
  } else if (diaChiNgay === "Dậu") {
    return (result = [
      { name: "Tướng Tinh", giap: "Dậu" },
      { name: "Đào Hoa", giap: "Ngọ" },
      { name: "Cô Thần", giap: "Hợi" },
      { name: "Kiếp Sát", giap: "Dần" },
      { name: "Dịch Mã", giap: "Hợi" },
      { name: "Quả Tú", giap: "Mùi" },
    ]);
  } else if (diaChiNgay === "Tuất") {
    return (result = [
      { name: "Tướng Tinh", giap: "Ngọ" },
      { name: "Đào Hoa", giap: "Mão" },
      { name: "Cô Thần", giap: "Hợi" },
      { name: "Kiếp Sát", giap: "Hợi" },
      { name: "Dịch Mã", giap: "Thân" },
      { name: "Quả Tú", giap: "Mùi" },
    ]);
  } else if (diaChiNgay === "Hợi") {
    return (result = [
      { name: "Tướng Tinh", giap: "Mão" },
      { name: "Đào Hoa", giap: "Tý" },
      { name: "Cô Thần", giap: "Dần" },
      { name: "Kiếp Sát", giap: "Thân" },
      { name: "Dịch Mã", giap: "Tỵ" },
      { name: "Quả Tú", giap: "Tuất" },
    ]);
  }
  return result;
};

export const ThanSatThienCan = (thienCanNgay, key) => {
  let result = "";
  if (thienCanNgay === "Giáp") {
    if (key === "Mão") {
      return (result = "Dương Nhẫn");
    } else if (key === "Tỵ") {
      return (result = "Văn Xương");
    } else if (key === "Hợi") {
      return (result = "Học Đường");
    } else if (key === "Mùi" || key === "Sửu") {
      return (result = "Quý Nhân");
    } else if (key === "Thìn") {
      return (result = "Kim Quỹ");
    } else if (key === "Dậu") {
      return (result = "Lữu Hạ");
    } else if (key === "Thân" || key === "Ngọ") {
      return (result = "Hỗng Diễm");
    } else if (key === "Tý" || key === "Ngọ") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Ất") {
    if (key === "Thìn") {
      return (result = "Dương Nhẫn");
    } else if (key === "Ngọ") {
      return (result = "Văn Xương, Học Đường");
    } else if (key === "Thân" || key === "Tý") {
      return (result = "Quý Nhân");
    } else if (key === "Tỵ") {
      return (result = "Kim Quỹ");
    } else if (key === "Tuất") {
      return (result = "Lữu Hạ");
    } else if (key === "Thân" || key === "Ngọ") {
      return (result = "Hỗng Diễm");
    } else if (key === "Tý" || key === "Ngọ") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Bính") {
    if (key === "Ngọ") {
      return (result = "Dương Nhẫn");
    } else if (key === "Thân") {
      return (result = "Văn Xương");
    } else if (key === "Dần") {
      return (result = "Học Đường, Hồng Diễm");
    } else if (key === "Dậu") {
      return (result = "Quý Nhân, TCQN");
    } else if (key === "Hợi") {
      return (result = "Quý Nhân");
    } else if (key === "Mùi") {
      return (result = "Kim Quỹ, Lưu Hạ");
    } else if (key === "Mão") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Đinh") {
    if (key === "Mùi") {
      return (result = "Dương Nhẫn, Hồng Diễm");
    } else if (key === "Dậu") {
      return (result = "Văn Xương, Học Đường, Quý Nhân, TCQN");
    } else if (key === "Hợi") {
      return (result = "Quý Nhân");
    } else if (key === "Thân") {
      return (result = "Kim Quỹ, Lưu Hạ");
    } else if (key === "Mão") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Mậu") {
    if (key === "Ngọ") {
      return (result = "Dương Nhẫn");
    } else if (key === "Thân") {
      return (result = "Văn Xương");
    } else if (key === "Dần") {
      return (result = "Học Đường");
    } else if (key === "Mùi") {
      return (result = "Thiên Ất Quý Nhân, Kim Quỹ, TCQN");
    } else if (key === "Sửu") {
      return (result = "Thiên Ất Quý Nhân, TCQN");
    } else if (key === "Tỵ") {
      return (result = "Lưu Hạ");
    } else if (key === "Thìn") {
      return (result = "Hồng Diễm, TCQN");
    } else if (key === "Tuất") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Kỷ") {
    if (key === "Mùi") {
      return (result = "Dương Nhẫn, TCQN");
    } else if (key === "Dậu") {
      return (result = "Văn Xương, Học Đường");
    } else if (key === "Thân") {
      return (result = "Thiên Ất Quý Nhân, Kim Quỹ");
    } else if (key === "Tý") {
      return (result = "Thiên Ất Quý Nhân");
    } else if (key === "Ngọ") {
      return (result = "Lưu Hạ");
    } else if (key === "Thìn") {
      return (result = "Hồng Diễm, TCQN");
    } else if (key === "Tuất") {
      return (result = "TCQN");
    } else if (key === "Sửu") {
      return (result = "TCQN");
    }
  } else if (thienCanNgay === "Canh") {
    if (key === "Dậu") {
      return (result = ", Dương Nhẫn");
    } else if (key === "Hợi") {
      return (result = ", Văn Xương, TCQN");
    } else if (key === "Tỵ") {
      return (result = ", Học Đường");
    } else if (key === "Mùi" || key === "Sửu") {
      return (result = ", Thiên Ất Quý Nhân");
    } else if (key === "Tuất") {
      return (result = ", Kim Quỹ, Hồng Diễm");
    } else if (key === "Thìn") {
      return (result = ", Lưu Hạ");
    } else if (key === "Dần") {
      return (result = ", TCQN");
    }
  } else if (thienCanNgay === "Tân") {
    if (key === "Tuất") {
      return (result = ", Dương Nhẫn");
    } else if (key === "Tý") {
      return (result = ", Văn Xương");
    } else if (key === "Ngọ") {
      return (result = ", Học Đường, Thiên Ất Quý Nhân");
    } else if (key === "Dần") {
      return (result = ", Thiên Ất Quý Nhân, TCQN");
    } else if (key === "Hợi") {
      return (result = ", Kim Quỹ, TCQN");
    } else if (key === "Mão") {
      return (result = ", Lưu Hạ");
    } else if (key === "Dậu") {
      return (result = ", Hồng Diễm");
    }
  } else if (thienCanNgay === "Nhâm") {
    if (key === "Tý") {
      return (result = ", Dương Nhẫn, Hồng Diễm");
    } else if (key === "Dần") {
      return (result = ", Văn Xương, Lưu Hạ");
    } else if (key === "Thân") {
      return (result = ", Học Đường, TCQN");
    } else if (key === "Tỵ") {
      return (result = ", Thiên Ất Quý Nhân, TCQN");
    } else if (key === "Mão") {
      return (result = ", Thiên Ất Quý Nhân");
    } else if (key === "Sửu") {
      return (result = ", Kim Quỹ");
    } else if (key === "Tý") {
      return (result = ", Hồng Diễm");
    }
  } else if (thienCanNgay === "Quý") {
    if (key === "Sửu") {
      return (result = ", Dương Nhẫn");
    } else if (key === "Mão") {
      return (result = ", Văn Xương, Học Đường, Thiên Ất Quý Nhân");
    } else if (key === "Dần") {
      return (result = ", Kim Quỹ");
    } else if (key === "Tỵ") {
      return (result = ", Thiên Ất Quý Nhân, TCQN");
    } else if (key === "Hợi") {
      return (result = ", Lưu Hạ");
    } else if (key === "Thân") {
      return (result = ", Hồng Diễm, TCQN");
    }
  }
  return result;
};

export const ThanSatThienCanArr = (thienCanNgay) => {
  let result = "";
  if (thienCanNgay === "Giáp") {
    return (result = [
      { name: "Văn Xương", giap: "Tỵ" },
      { name: "Quý Nhân", giap: "Mùi, Sửu" },
    ]);
  } else if (thienCanNgay === "Ất") {
    return (result = [
      { name: "Văn Xương", giap: "Ngọ" },
      { name: "Quý Nhân", giap: "Thân, Tý" },
    ]);
  } else if (thienCanNgay === "Bính") {
    return (result = [
      { name: "Văn Xương", giap: "Thân" },
      { name: "Quý Nhân", giap: "Dậu, Hợi" },
    ]);
  } else if (thienCanNgay === "Đinh") {
    return (result = [
      { name: "Văn Xương", giap: "Dậu" },
      { name: "Quý Nhân", giap: "Dậu, Hợi" },
    ]);
  } else if (thienCanNgay === "Mậu") {
    return (result = [
      { name: "Văn Xương", giap: "Thân" },
      { name: "Quý Nhân", giap: "Mùi, Sửu" },
    ]);
  } else if (thienCanNgay === "Kỷ") {
    return (result = [
      { name: "Văn Xương", giap: "Dậu" },
      { name: "Quý Nhân", giap: "Thân, Tý" },
    ]);
  } else if (thienCanNgay === "Canh") {
    return (result = [
      { name: "Văn Xương", giap: "Hợi" },
      { name: "Quý Nhân", giap: "Mùi, Sửu" },
    ]);
  } else if (thienCanNgay === "Tân") {
    return (result = [
      { name: "Văn Xương", giap: "Tý" },
      { name: "Quý Nhân", giap: "Dần, Ngọ" },
    ]);
  } else if (thienCanNgay === "Nhâm") {
    return (result = [
      { name: "Văn Xương", giap: "Dần" },
      { name: "Quý Nhân", giap: "Tỵ, Mão" },
    ]);
  } else if (thienCanNgay === "Quý") {
    return (result = [
      { name: "Văn Xương", giap: "Mão" },
      { name: "Quý Nhân", giap: "Tỵ, Mão" },
    ]);
  }
};

export const TruongSinh = (thienCanNgay, diaChiKey) => {
  let result = "";
  if (thienCanNgay === "Giáp") {
    if (diaChiKey === "Hợi") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Tý") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Sửu") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Dần") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Mão") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Thìn") {
      return (result = "Suy");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Tử");
    } else if (diaChiKey === "Mùi") {
      return (result = "Mộ");
    } else if (diaChiKey === "Thân") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Dậu") {
      return (result = "Thai");
    } else if (diaChiKey === "Tuất") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Ất") {
    if (diaChiKey === "Ngọ") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Thìn") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Mão") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Dần") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Sửu") {
      return (result = "Suy");
    } else if (diaChiKey === "Tý") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Hợi") {
      return (result = "Tử");
    } else if (diaChiKey === "Tuất") {
      return (result = "Mộ");
    } else if (diaChiKey === "Dậu") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Thân") {
      return (result = "Thai");
    } else if (diaChiKey === "Mùi") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Bính") {
    if (diaChiKey === "Dần") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Mão") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Thìn") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Mùi") {
      return (result = "Suy");
    } else if (diaChiKey === "Thân") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Dậu") {
      return (result = "Tử");
    } else if (diaChiKey === "Tuất") {
      return (result = "Mộ");
    } else if (diaChiKey === "Hợi") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Tý") {
      return (result = "Thai");
    } else if (diaChiKey === "Sửu") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Đinh") {
    if (diaChiKey === "Dậu") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Thân") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Mùi") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Thìn") {
      return (result = "Suy");
    } else if (diaChiKey === "Mão") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Dần") {
      return (result = "Tử");
    } else if (diaChiKey === "Sửu") {
      return (result = "Mộ");
    } else if (diaChiKey === "Tý") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Hợi") {
      return (result = "Thai");
    } else if (diaChiKey === "Tuất") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Mậu") {
    if (diaChiKey === "Dần") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Mão") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Thìn") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Mùi") {
      return (result = "Suy");
    } else if (diaChiKey === "Thân") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Dậu") {
      return (result = "Tử");
    } else if (diaChiKey === "Tuất") {
      return (result = "Mộ");
    } else if (diaChiKey === "Hợi") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Tý") {
      return (result = "Thai");
    } else if (diaChiKey === "Sửu") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Kỷ") {
    if (diaChiKey === "Dậu") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Thân") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Mùi") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Thìn") {
      return (result = "Suy");
    } else if (diaChiKey === "Mão") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Dần") {
      return (result = "Tử");
    } else if (diaChiKey === "Sửu") {
      return (result = "Mộ");
    } else if (diaChiKey === "Tý") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Hợi") {
      return (result = "Thai");
    } else if (diaChiKey === "Tuất") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Canh") {
    if (diaChiKey === "Tỵ") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Mùi") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Thân") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Dậu") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Tuất") {
      return (result = "Suy");
    } else if (diaChiKey === "Hợi") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Tý") {
      return (result = "Tử");
    } else if (diaChiKey === "Sửu") {
      return (result = "Mộ");
    } else if (diaChiKey === "Dần") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Mão") {
      return (result = "Thai");
    } else if (diaChiKey === "Thìn") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Tân") {
    if (diaChiKey === "Tý") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Hợi") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Tuất") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Dậu") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Thân") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Mùi") {
      return (result = "Suy");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Tử");
    } else if (diaChiKey === "Thìn") {
      return (result = "Mộ");
    } else if (diaChiKey === "Mão") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Dần") {
      return (result = "Thai");
    } else if (diaChiKey === "Sửu") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Nhâm") {
    if (diaChiKey === "Thân") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Dậu") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Tuất") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Hợi") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Tý") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Sửu") {
      return (result = "Suy");
    } else if (diaChiKey === "Dần") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Mão") {
      return (result = "Tử");
    } else if (diaChiKey === "Thìn") {
      return (result = "Mộ");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Thai");
    } else if (diaChiKey === "Mùi") {
      return (result = "Dưỡng");
    }
  } else if (thienCanNgay === "Quý") {
    if (diaChiKey === "Mão") {
      return (result = "Trường Sinh");
    } else if (diaChiKey === "Dần") {
      return (result = "Mộc Dục");
    } else if (diaChiKey === "Sửu") {
      return (result = "Quan Đới");
    } else if (diaChiKey === "Tý") {
      return (result = "Lâm Quan");
    } else if (diaChiKey === "Hợi") {
      return (result = "Đế Vượng");
    } else if (diaChiKey === "Tuất") {
      return (result = "Suy");
    } else if (diaChiKey === "Dậu") {
      return (result = "Bệnh");
    } else if (diaChiKey === "Thân") {
      return (result = "Tử");
    } else if (diaChiKey === "Mùi") {
      return (result = "Mộ");
    } else if (diaChiKey === "Ngọ") {
      return (result = "Tuyệt");
    } else if (diaChiKey === "Tỵ") {
      return (result = "Thai");
    } else if (diaChiKey === "Thìn") {
      return (result = "Dưỡng");
    }
  }
};

export const DaiVanDay = (field, gender, tangCan, namTietKhi, tietKhiDate) => {


  let thangTietKhi;

  const getThangSinh = field.getMonth();

  const getThangTietKhi = tietKhiDate.find(
    (element) => element.getMonth() == getThangSinh
  );


  thangTietKhi = namTietKhi.map(Number).indexOf(+getThangTietKhi);

  // index cua thang tiet khi
  const amDuong = tangCan.split(" ");

  if (field > getThangTietKhi) {
    if (amDuong[0] === "Dương" && gender === "Nam") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi + 1]);


      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Âm" && gender === "Nam") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Dương" && gender === "Nữ") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Âm" && gender === "Nữ") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi + 1]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    }
  } else if (field < getThangTietKhi) {

    if (amDuong[0] === "Dương" && gender === "Nam") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Âm" && gender === "Nam") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi - 1]);


      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Dương" && gender === "Nữ") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi - 1]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;

    } else if (amDuong[0] === "Âm" && gender === "Nữ") {

      const diffTime = Math.abs(field - namTietKhi[thangTietKhi]);

      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    }
  }
};

export const soNgayDaiVan = (soNgay) => {

  if (soNgay === 1) return 1;
  if (soNgay === 0) return 1;

  let result = (soNgay / 3).toFixed(1);

  result = Math.round(+result);


  return result;
};


export const DaiVan = (
  time,
  soNgay,
  gender,
  nguHanhh,
  thienCanMonth,
  diaChiMonth,
  thienCanNgay,
  diachiNgay,
  checkYear,
  daiVanDays
) => {

  let result = [];

  let thapTHAN;

  let arrThapThan;

  let thienCanDaiVan;

  let arrNguHanh;

  let arrTangCan;

  let diaChiDaiVan;

  let arrNapAm;

  let arrTruongSinh;

  let arrThanSatThienCan;

  let arrThanSatDiaChi;

  let nguyetChii;

  let thienYY;

  let khoiCuongg;

  let KhongVong = "";

  let date;


  if (!checkYear) date = time.getFullYear() + soNgay;
  else date = (time.getFullYear() - 1000) + soNgay;


  const nguHanhArr = nguHanhh.split(" ");

  const index = Const.thuTuThienCan.findIndex((x) => x === thienCanMonth);

  const indexDiaChi = Const.ConGiap.findIndex((x) => x === diaChiMonth);

  // object dau tien
  if (gender === "Nam" && nguHanhArr[0] === "Âm") {
    thienCanDaiVan = Const.thuTuThienCan[index - 1];

    diaChiDaiVan = Const.ConGiap[indexDiaChi - 1];

    if (thienCanDaiVan === undefined) {
      thienCanDaiVan = Const.thuTuThienCan[9];
    }
    if (diaChiDaiVan === undefined) {
      diaChiDaiVan = Const.ConGiap[11];
    }

    thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
    arrTangCan = TangCan(diaChiDaiVan);
    arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
    arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
    arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
    arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
    arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
    nguyetChii = NguyetChi(diaChiDaiVan);
    thienYY = ThienY(diaChiDaiVan);
    khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
    KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

    result.push({
      soNgay: soNgay,
      thienCan: thienCanDaiVan,
      nguHanhTC: arrNguHanh,
      diaChi: diaChiDaiVan,
      thapThan: thapTHAN,
      napAm: arrNapAm,
      truongSinh: arrTruongSinh,
      thanSatThienCan: arrThanSatThienCan,
      year: date,
      TangCan: arrTangCan,
      thanSatDiaChi: arrThanSatDiaChi,
      nguyetChi: nguyetChii,
      thienY: thienYY,
      khoiCuong: khoiCuongg,
      khongVong: KhongVong,
    });
  } else if (gender === "Nam" && nguHanhArr[0] === "Dương") {

    thienCanDaiVan = Const.thuTuThienCan[index + 1];

    diaChiDaiVan = Const.ConGiap[indexDiaChi + 1];

    if (thienCanDaiVan === undefined) {
      thienCanDaiVan = Const.thuTuThienCan[0];
    }
    if (diaChiDaiVan === undefined) {
      diaChiDaiVan = Const.ConGiap[0];
    }
    thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
    arrTangCan = TangCan(diaChiDaiVan);
    arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
    arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
    arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
    arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
    arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
    nguyetChii = NguyetChi(diaChiDaiVan);
    thienYY = ThienY(diaChiDaiVan);
    khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
    KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

    result.push({
      soNgay: soNgay,
      thienCan: thienCanDaiVan,
      nguHanhTC: arrNguHanh,
      diaChi: diaChiDaiVan,
      thapThan: thapTHAN,
      napAm: arrNapAm,
      truongSinh: arrTruongSinh,
      TangCan: arrTangCan,
      khongVong: KhongVong,

      thanSatThienCan: arrThanSatThienCan,
      year: date,
      thanSatDiaChi: arrThanSatDiaChi,
      nguyetChi: nguyetChii,
      thienY: thienYY,
      khoiCuong: khoiCuongg,
    });
  } else if (gender === "Nữ" && nguHanhArr[0] === "Dương") {
    thienCanDaiVan = Const.thuTuThienCan[index - 1];
    diaChiDaiVan = Const.ConGiap[indexDiaChi - 1];

    if (thienCanDaiVan === undefined) {
      thienCanDaiVan = Const.thuTuThienCan[9];
    }
    if (diaChiDaiVan === undefined) {
      diaChiDaiVan = Const.ConGiap[11];
    }

    thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
    arrTangCan = TangCan(diaChiDaiVan);
    arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
    arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
    arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
    arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
    arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
    nguyetChii = NguyetChi(diaChiDaiVan);
    thienYY = ThienY(diaChiDaiVan);
    khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
    KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

    result.push({
      soNgay: soNgay,
      thienCan: thienCanDaiVan,
      nguHanhTC: arrNguHanh,
      khongVong: KhongVong,

      diaChi: diaChiDaiVan,
      thapThan: thapTHAN,
      truongSinh: arrTruongSinh,
      TangCan: arrTangCan,

      thanSatThienCan: arrThanSatThienCan,
      napAm: arrNapAm,
      year: date,
      thanSatDiaChi: arrThanSatDiaChi,
      nguyetChi: nguyetChii,
      thienY: thienYY,
      khoiCuong: khoiCuongg,
    });
  } else if (gender === "Nữ" && nguHanhArr[0] === "Âm") {
    thienCanDaiVan = Const.thuTuThienCan[index + 1];
    diaChiDaiVan = Const.ConGiap[indexDiaChi + 1];

    if (thienCanDaiVan === undefined) {
      thienCanDaiVan = Const.thuTuThienCan[0];
    }
    if (diaChiDaiVan === undefined) {
      diaChiDaiVan = Const.ConGiap[0];
    }

    arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
    arrTangCan = TangCan(diaChiDaiVan);
    arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
    arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
    arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
    arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
    arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
    nguyetChii = NguyetChi(diaChiDaiVan);
    thienYY = ThienY(diaChiDaiVan);
    khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
    KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

    result.push({
      soNgay: soNgay,
      thienCan: thienCanDaiVan,
      diaChi: diaChiDaiVan,
      thapThan: arrThapThan,
      napAm: arrNapAm,
      truongSinh: arrTruongSinh,
      TangCan: arrTangCan,
      nguHanhTC: arrNguHanh,
      khongVong: KhongVong,

      thanSatThienCan: arrThanSatThienCan,
      year: date,
      thanSatDiaChi: arrThanSatDiaChi,
      nguyetChi: nguyetChii,
      thienY: thienYY,
      khoiCuong: khoiCuongg,
    });
  }

  for (let i = 0; i < 8; i++) {
    soNgay = soNgay + 10;

    date = date + 10;

    let indexThienCanNew = Const.thuTuThienCan.findIndex(
      (v) => v === thienCanDaiVan
    );

    let diaChiDaiVanNew = Const.ConGiap.findIndex((v) => v === diaChiDaiVan);

    if (gender === "Nam" && nguHanhArr[0] === "Âm") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew - 1];

      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew - 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[9];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[11];
      }

      thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soNgay,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,
        diaChi: diaChiDaiVan,
        thapThan: thapTHAN,
        napAm: arrNapAm,
        truongSinh: arrTruongSinh,
        TangCan: arrTangCan,
        thanSatThienCan: arrThanSatThienCan,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nam" && nguHanhArr[0] === "Dương") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew + 1];

      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew + 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[0];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[0];
      }

      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soNgay,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,
        thanSatThienCan: arrThanSatThienCan,
        napAm: arrNapAm,
        year: date,
        TangCan: arrTangCan,

        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nữ" && nguHanhArr[0] === "Dương") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew - 1];
      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew - 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[9];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[11];
      }

      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soNgay,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,

        thanSatThienCan: arrThanSatThienCan,
        TangCan: arrTangCan,

        napAm: arrNapAm,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nữ" && nguHanhArr[0] === "Âm") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew + 1];
      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew + 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[0];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[0];
      }
      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soNgay,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,

        thanSatThienCan: arrThanSatThienCan,
        TangCan: arrTangCan,

        napAm: arrNapAm,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    }
  }
  let arrNew = result?.sort((a, b) => a?.soNgay - b?.soNgay);

  return arrNew;
};

export const DaiVanCloneFunc = (
  time,
  soNgay,
  gender,
  nguHanhh,
  thienCanMonth,
  diaChiMonth,
  thienCanNgay,
  diachiNgay,
  checkYear,
  soTuoi
) => {

  let result = [];

  let thapTHAN;

  let arrThapThan;

  let thienCanDaiVan;

  let arrNguHanh;

  let arrTangCan;

  let diaChiDaiVan;

  let arrNapAm;

  let arrTruongSinh;

  let arrThanSatThienCan;

  let arrThanSatDiaChi;

  let nguyetChii;

  let thienYY;

  let khoiCuongg;

  let KhongVong = "";

  let date;

  // if (!checkYear) date = time.getFullYear() + soNgay;
  // else date = (time.getFullYear() - 1000) + soNgay;
  date = soNgay

  const nguHanhArr = nguHanhh.split(" ");

  const index = Const.thuTuThienCan.findIndex((x) => x === thienCanMonth);

  const indexDiaChi = Const.ConGiap.findIndex((x) => x === diaChiMonth);

  // object dau tien
  // if (gender === "Nam" && nguHanhArr[0] === "Âm") {
  //   thienCanDaiVan = Const.thuTuThienCan[index - 1];

  //   diaChiDaiVan = Const.ConGiap[indexDiaChi - 1];

  //   if (thienCanDaiVan === undefined) {
  //     thienCanDaiVan = Const.thuTuThienCan[9];
  //   }
  //   if (diaChiDaiVan === undefined) {
  //     diaChiDaiVan = Const.ConGiap[11];
  //   }

  //   thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
  //   arrTangCan = TangCan(diaChiDaiVan);
  //   arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
  //   arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
  //   arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
  //   arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
  //   arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
  //   nguyetChii = NguyetChi(diaChiDaiVan);
  //   thienYY = ThienY(diaChiDaiVan);
  //   khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
  //   KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

  //   result.push({
  //     soNgay: soTuoi,
  //     thienCan: thienCanDaiVan,
  //     nguHanhTC: arrNguHanh,
  //     diaChi: diaChiDaiVan,
  //     thapThan: thapTHAN,
  //     napAm: arrNapAm,
  //     truongSinh: arrTruongSinh,
  //     thanSatThienCan: arrThanSatThienCan,
  //     year: date,
  //     TangCan: arrTangCan,
  //     thanSatDiaChi: arrThanSatDiaChi,
  //     nguyetChi: nguyetChii,
  //     thienY: thienYY,
  //     khoiCuong: khoiCuongg,
  //     khongVong: KhongVong,
  //   });
  // } else if (gender === "Nam" && nguHanhArr[0] === "Dương") {

  //   thienCanDaiVan = Const.thuTuThienCan[index + 1];

  //   diaChiDaiVan = Const.ConGiap[indexDiaChi + 1];

  //   if (thienCanDaiVan === undefined) {
  //     thienCanDaiVan = Const.thuTuThienCan[0];
  //   }
  //   if (diaChiDaiVan === undefined) {
  //     diaChiDaiVan = Const.ConGiap[0];
  //   }
  //   thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
  //   arrTangCan = TangCan(diaChiDaiVan);
  //   arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
  //   arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
  //   arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
  //   arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
  //   arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
  //   nguyetChii = NguyetChi(diaChiDaiVan);
  //   thienYY = ThienY(diaChiDaiVan);
  //   khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
  //   KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

  //   result.push({
  //     soNgay: soTuoi,
  //     thienCan: thienCanDaiVan,
  //     nguHanhTC: arrNguHanh,
  //     diaChi: diaChiDaiVan,
  //     thapThan: thapTHAN,
  //     napAm: arrNapAm,
  //     truongSinh: arrTruongSinh,
  //     TangCan: arrTangCan,
  //     khongVong: KhongVong,

  //     thanSatThienCan: arrThanSatThienCan,
  //     year: date,
  //     thanSatDiaChi: arrThanSatDiaChi,
  //     nguyetChi: nguyetChii,
  //     thienY: thienYY,
  //     khoiCuong: khoiCuongg,
  //   });
  // } else if (gender === "Nữ" && nguHanhArr[0] === "Dương") {
  //   thienCanDaiVan = Const.thuTuThienCan[index - 1];
  //   diaChiDaiVan = Const.ConGiap[indexDiaChi - 1];

  //   if (thienCanDaiVan === undefined) {
  //     thienCanDaiVan = Const.thuTuThienCan[9];
  //   }
  //   if (diaChiDaiVan === undefined) {
  //     diaChiDaiVan = Const.ConGiap[11];
  //   }

  //   thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
  //   arrTangCan = TangCan(diaChiDaiVan);
  //   arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
  //   arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
  //   arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
  //   arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
  //   arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
  //   nguyetChii = NguyetChi(diaChiDaiVan);
  //   thienYY = ThienY(diaChiDaiVan);
  //   khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
  //   KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

  //   result.push({
  //     soNgay: soTuoi,
  //     thienCan: thienCanDaiVan,
  //     nguHanhTC: arrNguHanh,
  //     khongVong: KhongVong,

  //     diaChi: diaChiDaiVan,
  //     thapThan: thapTHAN,
  //     truongSinh: arrTruongSinh,
  //     TangCan: arrTangCan,

  //     thanSatThienCan: arrThanSatThienCan,
  //     napAm: arrNapAm,
  //     year: date,
  //     thanSatDiaChi: arrThanSatDiaChi,
  //     nguyetChi: nguyetChii,
  //     thienY: thienYY,
  //     khoiCuong: khoiCuongg,
  //   });
  // } else if (gender === "Nữ" && nguHanhArr[0] === "Âm") {
  //   thienCanDaiVan = Const.thuTuThienCan[index + 1];
  //   diaChiDaiVan = Const.ConGiap[indexDiaChi + 1];

  //   if (thienCanDaiVan === undefined) {
  //     thienCanDaiVan = Const.thuTuThienCan[0];
  //   }
  //   if (diaChiDaiVan === undefined) {
  //     diaChiDaiVan = Const.ConGiap[0];
  //   }

  //   arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
  //   arrTangCan = TangCan(diaChiDaiVan);
  //   arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
  //   arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
  //   arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
  //   arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
  //   arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
  //   nguyetChii = NguyetChi(diaChiDaiVan);
  //   thienYY = ThienY(diaChiDaiVan);
  //   khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
  //   KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

  //   result.push({
  //     soNgay: soTuoi,
  //     thienCan: thienCanDaiVan,
  //     diaChi: diaChiDaiVan,
  //     thapThan: arrThapThan,
  //     napAm: arrNapAm,
  //     truongSinh: arrTruongSinh,
  //     TangCan: arrTangCan,
  //     nguHanhTC: arrNguHanh,
  //     khongVong: KhongVong,

  //     thanSatThienCan: arrThanSatThienCan,
  //     year: date,
  //     thanSatDiaChi: arrThanSatDiaChi,
  //     nguyetChi: nguyetChii,
  //     thienY: thienYY,
  //     khoiCuong: khoiCuongg,
  //   });
  // }

  for (let i = 0; i < 9; i++) {
    soTuoi = soTuoi + 1;
    date = date + 1;

    let indexThienCanNew = Const.thuTuThienCan.findIndex(
      (v) => v === thienCanDaiVan
    );

    let diaChiDaiVanNew = Const.ConGiap.findIndex((v) => v === diaChiDaiVan);

    if (gender === "Nam" && nguHanhArr[0] === "Âm") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew - 1];

      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew - 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[9];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[11];
      }

      thapTHAN = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soTuoi,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,
        diaChi: diaChiDaiVan,
        thapThan: thapTHAN,
        napAm: arrNapAm,
        truongSinh: arrTruongSinh,
        TangCan: arrTangCan,
        thanSatThienCan: arrThanSatThienCan,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nam" && nguHanhArr[0] === "Dương") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew + 1];

      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew + 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[0];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[0];
      }

      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soTuoi,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,
        thanSatThienCan: arrThanSatThienCan,
        napAm: arrNapAm,
        year: date,
        TangCan: arrTangCan,

        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nữ" && nguHanhArr[0] === "Dương") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew - 1];
      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew - 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[9];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[11];
      }

      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soTuoi,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,

        thanSatThienCan: arrThanSatThienCan,
        TangCan: arrTangCan,

        napAm: arrNapAm,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    } else if (gender === "Nữ" && nguHanhArr[0] === "Âm") {
      thienCanDaiVan = Const.thuTuThienCan[indexThienCanNew + 1];
      diaChiDaiVan = Const.ConGiap[diaChiDaiVanNew + 1];

      if (thienCanDaiVan === undefined) {
        thienCanDaiVan = Const.thuTuThienCan[0];
      }
      if (diaChiDaiVan === undefined) {
        diaChiDaiVan = Const.ConGiap[0];
      }
      arrThapThan = ThapThan(thienCanNgay, thienCanDaiVan);
      arrTangCan = TangCan(diaChiDaiVan);
      arrNapAm = getNamAp(thienCanDaiVan + " " + diaChiDaiVan);
      arrTruongSinh = TruongSinh(thienCanNgay, diaChiDaiVan);
      arrThanSatThienCan = ThanSatThienCan(thienCanNgay, thienCanDaiVan);
      arrThanSatDiaChi = ThanSatDiaChi(diachiNgay, diaChiDaiVan);
      arrNguHanh = nguHanh(thienCanNgay, thienCanDaiVan);
      nguyetChii = NguyetChi(diaChiDaiVan);
      thienYY = ThienY(diaChiDaiVan);
      khoiCuongg = KhoiCuong(thienCanDaiVan, diaChiDaiVan);
      KhongVong = KhongVong2(thienCanNgay + " " + diachiNgay, diaChiDaiVan);

      result.push({
        soNgay: soTuoi,
        thienCan: thienCanDaiVan,
        nguHanhTC: arrNguHanh,
        khongVong: KhongVong,

        diaChi: diaChiDaiVan,
        thapThan: arrThapThan,
        truongSinh: arrTruongSinh,

        thanSatThienCan: arrThanSatThienCan,
        TangCan: arrTangCan,

        napAm: arrNapAm,
        year: date,
        thanSatDiaChi: arrThanSatDiaChi,
        nguyetChi: nguyetChii,
        thienY: thienYY,
        khoiCuong: khoiCuongg,
      });
    }
  }
  let arrNew = result?.sort((a, b) => a?.soNgay - b?.soNgay);

  return arrNew;
};

export const ShowNamDaiVan = (year, thienCanNgay, soTuoi) => {
  const result = [];
  let thapThan
  let arrTangCan
  let thienCan
  let diaChi
  let newYear = year
  let newDate
  let arrNguHanh
  for (let index = 1; index < 11; index++) {

    newDate = new Date(new Date().setYear(newYear));
    thienCan = YearToCan(newDate.getFullYear())
    diaChi = YearToChi(newDate.getFullYear())
    thapThan = ThapThan(thienCanNgay, thienCan?.name)
    arrTangCan = TangCan(diaChi?.name)
    arrNguHanh = nguHanh(thienCanNgay, thienCan?.name);


    result.push({
      thienCan: thienCan?.name,
      diaChi: diaChi?.name,
      thapThan,
      TangCan: arrTangCan,
      year: newYear,
      soNgay: soTuoi,
      nguHanhTC: arrNguHanh,
    })
    soTuoi = soTuoi + 1
    newYear += 1;
  }
  return result

}






export const getNamtietKhi = (timeYear, excelLichvanNien) => {
  const year = timeYear.getFullYear();
  let lichTietKhi = [];

  const distanceNext = timeYear?.getFullYear() + 1 - 1800; //next  year
  const thangLeNext = distanceNext * 42 + 120;
  const thangChanNext = distanceNext * 42 + 122;

  const distanceAgo = timeYear?.getFullYear() - 1 - 1800; //ago year
  const thangLeAgo = distanceNext * 42 + 120;

  const distance = timeYear?.getFullYear() - 1800;
  const thangLe = distance * 42 + 120;
  const thangChan = distance * 42 + 122;

  if (year <= 1800 && year >= 1583) {
    let ngayGioDauThang1 = Const.tietKhi_1583_1800_thangLe[0]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang1Ago = Const.tietKhi_1583_1800_thangLe[0]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang3 = Const.tietKhi_1583_1800_thangLe[1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang5 = Const.tietKhi_1583_1800_thangLe[2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang7 = Const.tietKhi_1583_1800_thangLe[3]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang9 = Const.tietKhi_1583_1800_thangLe[4]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang11 = Const.tietKhi_1583_1800_thangLe[5]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang2 = Const.tietKhi_1583_1800_thangChan[0]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang4 = Const.tietKhi_1583_1800_thangChan[1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang6 = Const.tietKhi_1583_1800_thangChan[2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang8 = Const.tietKhi_1583_1800_thangChan[3]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang10 = Const.tietKhi_1583_1800_thangChan[4]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = Const.tietKhi_1583_1800_thangChan[5]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang12NextYear = Const.tietKhi_1583_1800_thangChan[5]
      .split(" - ")[0]
      .split(" ");

    const ngayDauThangMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );
    const ngayDauThangMotAgo = new Date(
      timeYear.getFullYear() + 1,
      Number(ngayGioDauThang1Ago[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1Ago[0].split("/")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[1])
    );

    const ngayDauThangHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );
    const ngayDauThangMuoiHaiNext = new Date(
      timeYear.getFullYear() - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[1])
    );

    lichTietKhi.push(
      ngayDauThangMuoiHaiNext,
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai,
      ngayDauThangMotAgo
    );
  } else if (year >= 0 && year <= 1582) {
    let ngayGioDauThang1 = Const.tietKhi_0_1582_thangLe[0]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang1Ago = Const.tietKhi_0_1582_thangLe[0]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang3 = Const.tietKhi_0_1582_thangLe[1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang5 = Const.tietKhi_0_1582_thangLe[2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang7 = Const.tietKhi_0_1582_thangLe[3]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang9 = Const.tietKhi_0_1582_thangLe[4]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang11 = Const.tietKhi_0_1582_thangLe[5]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang2 = Const.tietKhi_0_1582_thangChan[0]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang4 = Const.tietKhi_0_1582_thangChan[1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang6 = Const.tietKhi_0_1582_thangChan[2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang8 = Const.tietKhi_0_1582_thangChan[3]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang10 = Const.tietKhi_0_1582_thangChan[4]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = Const.tietKhi_0_1582_thangChan[5]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang12NextYear = Const.tietKhi_0_1582_thangChan[5]
      .split(" - ")[0]
      .split(" ");

    const ngayDauThangMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );

    const ngayDauThangMotAgo = new Date(
      timeYear.getFullYear() + 1,
      Number(ngayGioDauThang1Ago[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1Ago[0].split("/")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[1])
    );

    const ngayDauThangHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );
    const ngayDauThangMuoiHaiNext = new Date(
      timeYear.getFullYear() - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[1])
    );

    lichTietKhi.push(
      ngayDauThangMuoiHaiNext,
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai,
      ngayDauThangMotAgo
    );
  } else {
    let ngayGioDauThang1 = excelLichvanNien[thangLe][2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang1Ago = excelLichvanNien[thangLeAgo] ? excelLichvanNien[thangLeAgo][2]?.split(" - ")[0]?.split(" ") : excelLichvanNien[thangLe][2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang3 = excelLichvanNien[thangLe][6]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang5 = excelLichvanNien[thangLe][10]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang7 = excelLichvanNien[thangLe][14]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang9 = excelLichvanNien[thangLe][18]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang11 = excelLichvanNien[thangLe][22]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang2 = excelLichvanNien[thangChan][2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang4 = excelLichvanNien[thangChan][6]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang6 = excelLichvanNien[thangChan][10]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang8 = excelLichvanNien[thangChan][14]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang10 = excelLichvanNien[thangChan][18]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = excelLichvanNien[thangChan][22]
      .split(" - ")[0]
      .split(" ");

    let ngayGioDauThang12NextYear = excelLichvanNien[thangChanNext] ? excelLichvanNien[thangChanNext][22]
      .split(" - ")[0]
      .split(" ") : excelLichvanNien[thangChan][22]
        .split(" - ")[0]
        .split(" ");

    const ngayDauThangMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );

    const ngayDauThangMotAgo = new Date(
      timeYear.getFullYear() + 1,
      Number(ngayGioDauThang1Ago[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1Ago[0].split("/")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[0]),
      Number(ngayGioDauThang1Ago[1].split(":")[1])
    );

    const ngayDauThangHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      timeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );

    const ngayDauThangMuoiHaiNext = new Date(
      timeYear.getFullYear() - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12NextYear[0].split("/")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[0]),
      Number(ngayGioDauThang12NextYear[1].split(":")[1])
    );

    lichTietKhi.push(
      ngayDauThangMuoiHaiNext,
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai,
      ngayDauThangMotAgo
    );
  }

  return lichTietKhi;
};

export const TangCanArr = (arrDiaChi, thienCanNgay) => {
  let result = [];

  arrDiaChi = arrDiaChi.reverse();

  arrDiaChi.map((x) => {
    let a = TangCan(x.diaChi);
    let b = ThapThan(thienCanNgay, x.thienCan);
    let c = ThapThan(thienCanNgay, x.TangCan[0]?.name);
    let d = ThapThan(thienCanNgay, x.TangCan[1]?.name);
    let e = ThapThan(thienCanNgay, x.TangCan[2]?.name);
    let years = x?.year;
    let soNgay = x?.soNgay
    result.push({
      TangCan: a,
      ThapThan: b,
      ThapThan1: c,
      ThapThan2: d,
      ThapThan3: e,
      year: years,
      soNgay
    });
  });

  return result;
};

export const DaiVan10Nam = (tempTime, soNgay, checkYear) => {
  let result = [];
  let year;

  if (!checkYear) year = tempTime.getFullYear() + soNgay;
  else year = (tempTime.getFullYear() - 1000) + soNgay;


  let date = new Date(new Date().setYear(year));

  let cot2 = DaiVan10NamB(year, 1);
  let arrNew2 = cot2?.sort((a, b) => b?.year - a?.year);

  let cot3 = DaiVan10NamB(year, 2);
  let arrNew3 = cot3?.sort((a, b) => b?.year - a?.year);

  let cot4 = DaiVan10NamB(year, 3);
  let arrNew4 = cot4?.sort((a, b) => b?.year - a?.year);

  let cot5 = DaiVan10NamB(year, 4);
  let arrNew5 = cot5?.sort((a, b) => b?.year - a?.year);

  let cot6 = DaiVan10NamB(year, 5);
  let arrNew6 = cot6?.sort((a, b) => b?.year - a?.year);

  let cot7 = DaiVan10NamB(year, 6);
  let arrNew7 = cot7?.sort((a, b) => b?.year - a?.year);

  let cot8 = DaiVan10NamB(year, 7);
  let arrNew8 = cot8?.sort((a, b) => b?.year - a?.year);

  let cot9 = DaiVan10NamB(year, 8);
  let arrNew9 = cot9?.sort((a, b) => b?.year - a?.year);

  let cot10 = DaiVan10NamB(year, 9);
  let arrNew10 = cot10?.sort((a, b) => b?.year - a?.year);

  result.push({
    cot1: {
      thienCan: YearToCan(date.getFullYear()),
      diaChi: YearToChi(date.getFullYear()),
      year: year,
    },
    cot2: arrNew2,
    cot3: arrNew3,
    cot4: arrNew4,
    cot5: arrNew5,
    cot6: arrNew6,
    cot7: arrNew7,
    cot8: arrNew8,
    cot9: arrNew9,
    cot10: arrNew10,
  });

  for (let index = 0; index < 8; index++) {
    year = year + 10;
    date = new Date(new Date().setYear(year));

    result.push({
      cot1: {
        thienCan: YearToCan(date.getFullYear()),
        diaChi: YearToChi(date.getFullYear()),
        year: year,
      },
    });
  }

  let arrNew = result?.sort((a, b) => b?.cot1?.year - a?.cot1?.year);

  return arrNew;
};

const DaiVan10NamB = (year, number) => {
  let result = [];
  let newYear = year + number;
  let newDate = new Date(new Date().setYear(newYear));

  result.push({
    thienCan: YearToCan(newDate.getFullYear()),
    diaChi: YearToChi(newDate.getFullYear()),
    year: newYear,
  });

  for (let index = 0; index < 8; index++) {
    newYear = newYear + 10;
    newDate = new Date(new Date().setYear(newYear));

    result.push({
      thienCan: YearToCan(newDate.getFullYear()),
      diaChi: YearToChi(newDate.getFullYear()),
      year: newYear,
    });
  }
  let arrNew = result?.sort((a, b) => a?.newYear - b?.newYear);

  return arrNew;
};

export const reverseArr = (arr) => {
  let arrNew = [];

  arrNew = arr.reverse();

  return arrNew;
};

export function ThapThanArr(thienCanNgay, arrTangCan) {
  let result = [];

  let thapthan = ThapThan(thienCanNgay, arrTangCan[0]?.TangCan[0]?.name);
  let thapthan2 = ThapThan(thienCanNgay, arrTangCan[0]?.TangCan[1]?.name);
  let thapthan3 = ThapThan(thienCanNgay, arrTangCan[0]?.TangCan[2]?.name);

  result.push({
    thapThan: thapthan,
    thapThan2: thapthan2,
    thapThan3: thapthan3,
  });

  return result;
}

export function ThapThanArr2(thienCanNgay, arrTangCan) {
  let result = [];

  let thapthan = ThapThan(thienCanNgay, arrTangCan[0]?.name);
  let thapthan2 = ThapThan(thienCanNgay, arrTangCan[1]?.name);
  let thapthan3 = ThapThan(thienCanNgay, arrTangCan[2]?.name);

  result.push({
    thapThan: thapthan,
    thapThan2: thapthan2,
    thapThan3: thapthan3,
  });

  return result;
}

export function solarTime(solarNoon, userTime) {
  let result;

  let apu = solarNoon;

  let midDayBySun2 = apu.toString().substring(16, 24);
  midDayBySun2 = midDayBySun2.replace(/:/g, ".");
  let sunNoonSecods2 = hmsToSecondsOnly(midDayBySun2);

  let b = userTime.toLocaleTimeString("en-US");
  let b2 = b.replace(/:/g, ".");

  let secondsTimeZone = hmsToSecondsOnly(b2);

  // if clock shows value with PM, add 12hours.
  if (b.includes("AM") && b.substring(0, 2).includes("12")) {
    secondsTimeZone = secondsTimeZone + 43200;
  }

  if (b.includes("PM") && !b.substring(0, 2).includes("12")) {
    secondsTimeZone = secondsTimeZone + 43200;
  }

  let sunTimeSeconds2 = sunNoonSecods2 - 43200;
  let sunTimeApu = secondsTimeZone - sunTimeSeconds2;

  let sunTimeDate = new Date(null);
  let offset = sunTimeDate.getTimezoneOffset();
  offset = offset * 60;
  sunTimeDate.setSeconds(sunTimeApu + offset);

  let h2 = sunTimeDate.getHours();
  let m2 = sunTimeDate.getMinutes();
  let s2 = sunTimeDate.getSeconds();

  return (result = {
    hour: h2,
    minutes: m2,
    second: s2,
  });
}
function hmsToSecondsOnly(str) {
  let p = str.split("."),
    s = 0,
    m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
}
export const Prev = (year, thienCanNgay, diaChiNgay) => {
  year = year - 1;

  const prevYear = new Date(`${year}`);

  const thienCanPrev = YearToCan(prevYear.getFullYear());
  const nguhanhtc = nguHanh(thienCanNgay, thienCanPrev.name);

  const diaChiPrev = YearToChi(prevYear.getFullYear());
  const thanSatThienCanPrev = ThanSatThienCan(thienCanNgay, thienCanPrev.name);
  const thanSatDiaChiPrev = ThanSatDiaChi(diaChiNgay, diaChiPrev.name);
  const tangCan = TangCan(diaChiPrev.name);
  const TruongSinhh = TruongSinh(thienCanNgay, diaChiPrev.name);

  const napAmm = getNamAp(thienCanPrev.name + " " + diaChiPrev.name);

  const ThapThanTangCan = ThapThanArr2(thienCanNgay, tangCan);

  const result = [];

  result.push({
    thienCan: thienCanPrev.name,
    diaChi: diaChiPrev.name,
    tangCan: TangCan(diaChiPrev.name),

    thapThan: ThapThan(thienCanNgay, thienCanPrev.name),
    thanSatThienCan: thanSatThienCanPrev,
    thanSatDiaChi: thanSatDiaChiPrev,
    thapThanArr: ThapThanTangCan,
    napAm: napAmm,
    truongSinh: TruongSinhh,
    nguHanh: nguhanhtc,
    year: year,
  });
  return result;
};

export const Next = (year, thienCanNgay, diaChiNgay) => {
  year = year + 1;

  const prevYear = new Date(`${year}`);

  const thienCanPrev = YearToCan(prevYear.getFullYear());
  const nguhanhtc = nguHanh(thienCanNgay, thienCanPrev.name);
  const diaChiPrev = YearToChi(prevYear.getFullYear());
  const thanSatThienCanPrev = ThanSatThienCan(thienCanNgay, thienCanPrev.name);
  const thanSatDiaChiPrev = ThanSatDiaChi(diaChiNgay, diaChiPrev.name);
  const tangCan = TangCan(diaChiPrev.name);
  const TruongSinhh = TruongSinh(thienCanNgay, diaChiPrev.name);

  const napAmm = getNamAp(thienCanPrev.name + " " + diaChiPrev.name);

  const ThapThanTangCan = ThapThanArr2(thienCanNgay, tangCan);

  const result = [];

  result.push({
    thienCan: thienCanPrev.name,
    diaChi: diaChiPrev.name,
    tangCan: TangCan(diaChiPrev.name),
    thapThan: ThapThan(thienCanNgay, thienCanPrev.name),
    thanSatThienCan: thanSatThienCanPrev,
    thanSatDiaChi: thanSatDiaChiPrev,
    thapThanArr: ThapThanTangCan,
    napAm: napAmm,
    truongSinh: TruongSinhh,
    nguHanh: nguhanhtc,
    year: year,
  });
  return result;
};

export const mapData = (month) => {
  let dateData = Const.initDateData;

  let item;

  dateData.map((item) => {
    if (item.header === "Tháng") {
      //tách chuỗi name trong canChiMonth
      let tempCan = month.name.split(" ");

      item.canChi = tempCan[0];
      item.diaChi = tempCan[1];
    }

    return item;
  });
};

export const ThaiCung = (thienCanMonth, diaChiMonth) => {
  // thien can + 1
  // dia chi + 3
  const result = [];
  let thaiCungTC;
  let thaiCungDC;
  const indexTC = Const.thuTuThienCan.findIndex((x) => x === thienCanMonth);

  const indexDiaChi = Const.ConGiap.findIndex((x) => x === diaChiMonth);
  if (indexTC === 9) thaiCungTC = Const.thuTuThienCan[0];
  else thaiCungTC = Const.thuTuThienCan[indexTC + 1];

  let idxDC = indexDiaChi + 3;
  if (idxDC === 12) thaiCungDC = Const.ConGiap[0];
  else if (idxDC === 13) thaiCungDC = Const.ConGiap[1];
  else if (idxDC === 14) thaiCungDC = Const.ConGiap[2];
  else thaiCungDC = Const.ConGiap[indexDiaChi + 3];

  result.push({
    tc: thaiCungTC,
    dc: thaiCungDC,
  });
  return result;
};

export const MenhCung = (thangSinh, gioSinh, canYear) => {
  const result = [];
  // convert hour
  let chiHour = convertHour(gioSinh);

  let canchiMonth = MonthToCanChi(canYear.name, thangSinh);
  let tempCan = canchiMonth?.name.split(" ");

  let soThangSinh = Const.thuTuMenhCung.findIndex((x) => x === tempCan[1]);
  let soGioSinh = Const.thuTuMenhCung.findIndex((x) => x === chiHour?.name);
  // 14 and 26 la hang so
  soThangSinh += 1;
  soGioSinh += 1;

  let soA = soThangSinh + soGioSinh;
  let kq;
  let MenhCung = [];

  if (soA >= 14) kq = 26 - (soThangSinh + soGioSinh);
  else kq = 14 - (soThangSinh + soGioSinh);

  if (kq === 12) kq = 0;

  let canChiCung = MonthToCanChi(canYear.name, kq);
  let tempCung = canChiCung?.name.split(" ");

  MenhCung.push({
    tc: tempCung[0],
    dc: tempCung[1],
  });
  return MenhCung;
};


export const KetLuanLaSo = (chiThang, chiNam, chiNgay, chiGio, canNam, canThang, canGio, canNgay, diaChiThang, thienCanHour, thienCanDay, thienCanYear) => {
  let amDuong = canNgay.split(" ")[0]
  chiThang = chiThang.split(" ")[1]
  chiNam = chiNam.split(" ")[1]
  chiNgay = chiNgay.split(" ")[1]
  chiGio = chiGio.split(" ")[1]
  canNam = canNam.split(" ")[1]
  canThang = canThang.split(" ")[1]
  canGio = canGio.split(" ")[1]
  canNgay = canNgay.split(" ")[1]
  let result = []
  let laSo = ""
  let DungThanPhuUc = ''
  let KyThanPhuUc = ''
  let laSoYeu = false
  let dungThan = ""
  let a = false
  let b = 0
  let checkThang = false
  let c = 0

  let case1 = false
  let case2 = false
  let case3 = false

  if (diaChiThang === "Thìn") {
    if (thienCanHour === "Giáp" || thienCanHour === "Ất" || thienCanDay === "Giáp" || thienCanDay === "Ất" || thienCanYear === "Giáp" || thienCanYear === "Ất") {
      chiThang = "Mộc"
      checkThang = true
    }
  } else if (diaChiThang === "Mùi") {
    if (thienCanHour === "Bính" || thienCanHour === "Đinh" || thienCanDay === "Bính" || thienCanDay === "Đinh" || thienCanYear === "Bính" || thienCanYear === "Đinh") {
      chiThang = "Hỏa"
      checkThang = true

    }
  } else if (diaChiThang === "Tuất") {
    if (thienCanHour === "Canh" || thienCanHour === "Tân" || thienCanDay === "Canh" || thienCanDay === "Tân" || thienCanYear === "Canh" || thienCanYear === "Tân") {
      chiThang = "Kim"
      checkThang = true

    }
  } else if (diaChiThang === "Sửu") {
    if (thienCanHour === "Nhâm" || thienCanHour === "Quý" || thienCanDay === "Giáp" || thienCanDay === "Quý" || thienCanYear === "Giáp" || thienCanYear === "Quý") {
      chiThang = "Thủy"
      checkThang = true

    }
  }
  //tieu chi 1
  const tieuChi1 = checkNguHanh(chiThang, canNgay)
  if (tieuChi1) {
    a = true
    case1 = true
  }

  // tieu chi 2

  const tieuChi2_1 = checkNguHanh(canNam, canNgay)
  const tieuChi2_2 = checkNguHanh(canThang, canNgay)
  const tieuChi2_3 = checkNguHanh(canGio, canNgay)
  if (tieuChi2_1) b = 1
  if (tieuChi2_2) b++
  if (tieuChi2_3) b++
  if (b === 2 || b === 3) {
    case2 = true
  } else {
    case2 = false
  }

  // tieu chi 3
  const tieuChi3_1 = checkNguHanh(chiNam, canNgay)
  const tieuChi3_2 = checkNguHanh(chiNgay, canNgay)
  const tieuChi3_3 = checkNguHanh(chiGio, canNgay)
  if (tieuChi3_1) c = 1
  if (tieuChi3_2) c++
  if (tieuChi3_3) c++
  if (c === 2 || c === 3) {
    case3 = true
  } else {
    case3 = false
  }

  let tuongSinh1 = TuongSinh(chiThang, canNgay)
  let tuongSinh2 = TuongSinh(chiGio, canNgay)
  let tuongSinh3 = TuongSinh(chiNam, canNgay)
  let tuongSinh4 = TuongSinh(chiNgay, canNgay)
  let tuongSinh5 = TuongSinh(canGio, canNgay)
  let tuongSinh6 = TuongSinh(canNam, canNgay)
  let tuongSinh7 = TuongSinh(canThang, canNgay)

  if (tuongSinh5 || tuongSinh6 || tuongSinh7) {
    laSoYeu = true
  }

  let laSo2 = checkLaSo2(a, b, c, amDuong, laSoYeu)

  if (laSo2) {
    laSo = laSo2
  } else {
    laSo = checkLaSo(case1, case2, case3)
  }

  if (laSo === "Lá số mạnh") {
    // yếu lại
    if (canNgay === "Kim") {

      if (canNam === "Hỏa") {
        if (TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Thủy, Mộc"
          dungThan = "Thủy, Mộc"
        }
      } else if (canThang === "Hỏa") {

        if (TuongSinh(canThang, canNam) || TuongSinh(canThang, canGio)) {
          DungThanPhuUc = "Thủy, Mộc"
          dungThan = "Thủy, Mộc"
        }
      } else if (canGio === "Hỏa") {
        if (TuongSinh(canGio, canThang) || TuongSinh(canGio, canNam)) {
          DungThanPhuUc = "Thủy, Mộc"
          dungThan = "Thủy, Mộc"
        }
      }
      if (canGio === "Thủy" || canNam === "Thủy" || canThang === "Thủy") {
        DungThanPhuUc = "Thủy, Mộc"
        dungThan = "Thủy, Mộc"
      }
      if (canGio === "Thổ" || canNam === "Thổ" || canThang === "Thổ") {
        DungThanPhuUc = "Thủy, Mộc"
        dungThan = "Thủy, Mộc"
      }
      if (canGio !== "Thổ" && canNam !== "Thổ" && canThang !== "Thổ") {
        DungThanPhuUc = "Hỏa ,Thủy, Mộc"
        dungThan = "Hỏa ,Thủy, Mộc"
      }

    }
    if (canNgay === "Mộc") {
      if (canNam === "Kim") {
        if (TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Hỏa, Thổ"
          dungThan = "Hỏa, Thổ"
        }

      } else if (canThang === "Kim") {

        if (TuongSinh(canThang, canNam) || TuongSinh(canThang, canGio)) {
          DungThanPhuUc = "Hỏa, Thổ"
          dungThan = "Hỏa, Thổ"
        }

      } else if (canGio === "Kim") {
        if (TuongSinh(canGio, canThang) || TuongSinh(canGio, canNam)) {
          DungThanPhuUc = "Hỏa, Thổ"
          dungThan = "Hỏa, Thổ"
        }

      }
      if (canGio === "Hỏa" || canNam === "Hỏa" || canThang === "Hỏa") {
        DungThanPhuUc = "Hỏa, Thổ"
        dungThan = "Hỏa, Thổ"
      }
      if (canGio === "Thủy" || canNam === "Thủy" || canThang === "Thủy") {
        DungThanPhuUc = "Hỏa, Thổ"
        dungThan = "Hỏa, Thổ"
      }
      if (canGio !== "Thủy" && canNam !== "Thủy" && canThang !== "Thủy") {
        DungThanPhuUc = "Kim, Hỏa, Thổ"
        dungThan = "Kim, Hỏa, Thổ"
      }
    }
    if (canNgay === "Thủy") {
      if (canNam === "Thổ") {
        if (TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Mộc, Hỏa"
          dungThan = "Mộc, Hỏa"
        }
      } else if (canThang === "Thổ") {
        if (TuongSinh(canThang, canNam) || TuongSinh(canThang, canGio)) {
          DungThanPhuUc = "Mộc, Hỏa"
          dungThan = "Mộc, Hỏa"
        }
      } else if (canGio === "Thổ") {
        if (TuongSinh(canGio, canThang) || TuongSinh(canGio, canNam)) {
          DungThanPhuUc = "Mộc, Hỏa"
          dungThan = "Mộc, Hỏa"
        }
      }

      if (canGio === "Mộc" || canNam === "Mộc" || canThang === "Mộc") {
        DungThanPhuUc = "Mộc, Hỏa"
        dungThan = "Mộc, Hỏa"
      }
      if (canGio === "Kim" || canNam === "Kim" || canThang === "Kim") {
        DungThanPhuUc = "Mộc, Hỏa"
        dungThan = "Mộc, Hỏa"
      }
      if (canGio !== "Kim" && canNam !== "Kim" && canThang !== "Kim") {
        DungThanPhuUc = "Mộc, Hỏa, Thổ"
        dungThan = "Mộc, Hỏa, Thổ"
      }



    }
    if (canNgay === "Hỏa") {
      if (canNam === "Thủy") {
        if (TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Thổ, Kim"
          dungThan = "Thổ, Kim"
        }
      } else if (canThang === "Thủy") {

        if (TuongSinh(canThang, canNam) || TuongSinh(canThang, canGio)) {
          DungThanPhuUc = "Thổ, Kim"
          dungThan = "Thổ, Kim"
        }
      } else if (canGio === "Thủy") {
        if (TuongSinh(canGio, canThang) || TuongSinh(canGio, canNam)) {
          DungThanPhuUc = "Thổ, Kim"
          dungThan = "Thổ, Kim"
        }
      }

      if (canGio === "Thổ" || canNam === "Thổ" || canThang === "Thổ") {
        DungThanPhuUc = "Thổ, Kim"
        dungThan = "Thổ, Kim"
      }

      if (canGio === "Mộc" || canNam === "Mộc" || canThang === "Mộc") {
        DungThanPhuUc = "Thổ, Kim"
        dungThan = "Thổ, Kim"
      }
      if (canGio !== "Mộc" && canNam !== "Mộc" && canThang !== "Mộc") {
        DungThanPhuUc = "Thủy, Thổ, Kim"
        dungThan = "Thủy, Thổ, Kim"
      }

    }
    if (canNgay === "Thổ") {

      if (canNam === "Mộc") {
        if (TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Kim, Thủy"
          dungThan = "Kim, Thủy"
        }
      } else if (canThang === "Mộc") {

        if (TuongSinh(canThang, canNam) || TuongSinh(canThang, canGio)) {
          DungThanPhuUc = "Kim, Thủy"
          dungThan = "Kim, Thủy"
        }
      } else if (canGio === "Mộc") {
        if (TuongSinh(canGio, canThang) || TuongSinh(canGio, canNam)) {
          DungThanPhuUc = "Kim, Thủy"
          dungThan = "Kim, Thủy"
        }
      }

      if (canGio === "Kim" || canNam === "Kim" || canThang === "Kim") {
        DungThanPhuUc = "Thủy, Kim"
        dungThan = "Thủy, Kim"
      }
      if (canGio === "Hỏa" || canNam === "Hỏa" || canThang === "Hỏa") {
        DungThanPhuUc = "Thủy, Kim"
        dungThan = "Thủy, Kim"
      }
      if (canGio !== "Hỏa" && canNam !== "Hỏa" && canThang !== "Hỏa") {
        DungThanPhuUc = "Thủy, Kim, Mộc"
        dungThan = "Thủy, Kim, Mộc"
      }
    }
  } else if (laSo === "Lá số yếu") {
    if (canNgay === "Kim") {
      if (canGio === "Thổ" || canNam === "Thổ" || canThang === "Thổ") {
        if (TuongSinh(canThang, canGio) || TuongSinh(canThang, canNam) || TuongSinh(canGio, canNam) || TuongSinh(canGio, canThang) || TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Kim, Thổ, Hỏa"
          dungThan = "Kim, Thổ, Hỏa"
        }
        if (canGio === "Thổ" || canNam === "Thổ" || canThang === "Thổ") {
          DungThanPhuUc = "Kim, Thổ, Hỏa"
          dungThan = "Kim, Thổ, Hỏa"
        }
      } else {
        DungThanPhuUc = "Thổ, Kim"
        dungThan = "Thổ, Kim"
      }

    }
    if (canNgay === "Mộc") {
      if (canGio === "Thủy" || canNam === "Thủy" || canThang === "Thủy") {
        if (TuongSinh(canThang, canGio) || TuongSinh(canThang, canNam) || TuongSinh(canGio, canNam) || TuongSinh(canGio, canThang) || TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Kim, Thủy, Mộc"
          dungThan = "Kim, Thủy, Mộc"
        }
        if (canGio === "Thủy" || canNam === "Thủy" || canThang === "Thủy") {
          DungThanPhuUc = "Kim, Thủy, Mộc"
          dungThan = "Kim, Thủy, Mộc"
        }
      } else {
        DungThanPhuUc = "Thủy, Mộc"
        dungThan = "Thủy, Mộc"
      }

    }
    if (canNgay === "Thủy") {
      if (canGio === "Kim" || canNam === "Kim" || canThang === "Kim") {
        if (TuongSinh(canThang, canGio) || TuongSinh(canThang, canNam) || TuongSinh(canGio, canNam) || TuongSinh(canGio, canThang) || TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Kim, Thủy, Thổ"
          dungThan = "Kim, Thủy, Thổ"
        }
        if (canGio === "Kim" || canNam === "Kim" || canThang === "Kim") {
          DungThanPhuUc = "Kim, Thủy, Thổ"
          dungThan = "Kim, Thủy, Thổ"
        }
      } else {
        DungThanPhuUc = "Kim, Thủy"
        dungThan = "Kim, Thủy"
      }

    }
    if (canNgay === "Hỏa") {
      if (canGio === "Mộc" || canNam === "Mộc" || canThang === "Mộc") {
        if (TuongSinh(canThang, canGio) || TuongSinh(canThang, canNam) || TuongSinh(canGio, canNam) || TuongSinh(canGio, canThang) || TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Mộc, Hỏa, Thủy"
          dungThan = "Mộc, Hỏa, Thủy"
        }
        if (canGio === "Mộc" || canNam === "Mộc" || canThang === "Mộc") {
          DungThanPhuUc = "Mộc, Hỏa, Thủy"
          dungThan = "Mộc, Hỏa, Thủy"
        }
      } else {
        DungThanPhuUc = "Mộc, Hỏa"
        dungThan = "Mộc, Hỏa"
      }

    }
    if (canNgay === "Thổ") {
      if (canGio === "Hỏa" || canNam === "Hỏa" || canThang === "Hỏa") {
        if (TuongSinh(canThang, canGio) || TuongSinh(canThang, canNam) || TuongSinh(canGio, canNam) || TuongSinh(canGio, canThang) || TuongSinh(canNam, canThang) || TuongSinh(canNam, canGio)) {
          DungThanPhuUc = "Mộc, Hỏa, Thổ"
          dungThan = "Mộc, Hỏa, Thổ"
        }
        if (canGio === "Hỏa" || canNam === "Hỏa" || canThang === "Hỏa") {
          DungThanPhuUc = "Mộc, Hỏa, Thổ"
          dungThan = "Mộc, Hỏa, Thổ"
        }
      } else {
        DungThanPhuUc = "Hỏa, Thổ"
        dungThan = "Hỏa, Thổ"
      }
    }
  } else if (laSo === "Lá cực mạnh" || laSo === "Lá số giả cực mạnh") {
    if (canNgay === "Kim") {
      DungThanPhuUc = "Thổ, Kim"
      dungThan = "Thổ, Kim"
    }
    if (canNgay === "Mộc") {

      DungThanPhuUc = "Thủy, Mộc"
      dungThan = "Thủy, Mộc"

    }
    if (canNgay === "Thủy") {

      DungThanPhuUc = "Kim, Thủy"
      dungThan = "Kim, Thủy"


    }
    if (canNgay === "Hỏa") {

      DungThanPhuUc = "Mộc, Hỏa"
      dungThan = "Mộc, Hỏa"

    }
    if (canNgay === "Thổ") {

      DungThanPhuUc = "Hỏa, Thổ"
      dungThan = "Hỏa, Thổ"

    }

  } else if (laSo === "Lá số giả cực yếu" || laSo === "Lá số cực yếu") {
    if (canNgay === "Kim") {


      DungThanPhuUc = "Thủy, Mộc, Hỏa"
      dungThan = "Thủy, Mộc, Hỏa"
    }
    if (canNgay === "Mộc") {

      DungThanPhuUc = "Hỏa, Thổ, Kim"
      dungThan = "Hỏa, Thổ, Kim"

    }
    if (canNgay === "Thủy") {

      DungThanPhuUc = "Mộc, Hỏa, Thổ"
      dungThan = "Mộc, Hỏa, Thổ"


    }
    if (canNgay === "Hỏa") {

      DungThanPhuUc = "Thổ, Kim, Thủy"
      dungThan = "Thổ, Kim, Thủy"

    }
    if (canNgay === "Thổ") {


      DungThanPhuUc = "Kim, Thủy, Mộc"
      dungThan = "Kim, Thủy, Mộc"

    }
  }

  const thongQuan = ThongQuan(canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam)
  const dungThanDieuHau = DieuHau(canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam)


  result.push({
    dungThanPhuUc: DungThanPhuUc,
    kyThanPhuUc: KyThanPhuUc,
    cachCuc: laSo,
    dungThan,
    thongQuan,
    dungThanDieuHau,
    checkThang

  })
  return result
}

function checkNguHanh(key, canNgay) {
  if (key === "Kim") {
    if (key === canNgay || canNgay === "Thủy") {
      return true
    } else {
      return false
    }
  }
  if (key === "Mộc") {
    if (key === canNgay || canNgay === "Hỏa") {
      return true
    } else {
      return false
    }
  }
  if (key === "Thủy") {
    if (key === canNgay || canNgay === "Mộc") {
      return true
    } else {
      return false
    }
  }
  if (key === "Hỏa") {
    if (key === canNgay || canNgay === "Thổ") {
      return true
    } else {
      return false
    }
  }
  if (key === "Thổ") {
    if (key === canNgay || canNgay === "Kim") {
      return true
    } else {
      return false
    }
  }
}

function checkLaSo(a, b, c) {


  if (!a && b && c) {
    return "Lá số mạnh"
  }

  if (a && b && !c) {
    return "Lá số mạnh"
  }

  if (a && !b && c) {
    return "Lá số mạnh"
  }

  if (!a && !b && c) {
    return "Lá số yếu"
  }
  if (a && !b && !c) {
    return "Lá số yếu"
  }
  if (!a && b && !c) {
    return "Lá số yếu"
  }


}

function checkLaSo2(a, b, c, amDuong, laSoYeu) {




  if (!a && b === 1 && c === 1) {
    return "Lá số yếu"
  }
  if (a && b === 2 && c === 2) {
    return "Lá số mạnh"
  }

  if (a && b === 3 && c === 3) {
    return "Lá số cực mạnh"
  }

  if (a && b === 2 && c === 3 || a && b === 3 && c === 2) {
    return "Lá số giả cực mạnh"
  }

  if (!a && b === 0 && c === 0) {
    if (amDuong === "Dương") {
      return "Lá số yếu"
    } else {
      return "Lá số cực yếu"
    }
  }
  if (!a && b === 1 && c === 0 || !a && b === 0 && c === 1) {
    if (laSoYeu || amDuong === "Dương") {

      return "Lá số yếu"
    } else {
      return "Lá số giả cực yếu"
    }
  }
}


function TuongSinh(key, canNgay) {
  if (key === "Kim") {
    if (canNgay === "Thủy") {
      return true
    } else {
      return false
    }
  }
  if (key === "Mộc") {
    if (canNgay === "Hỏa") {
      return true
    } else {
      return false
    }
  }
  if (key === "Thủy") {
    if (canNgay === "Mộc") {
      return true
    } else {
      return false
    }
  }
  if (key === "Hỏa") {
    if (canNgay === "Thổ") {
      return true
    } else {
      return false
    }
  }
  if (key === "Thổ") {
    if (canNgay === "Kim") {
      return true
    } else {
      return false
    }
  }
}



function ThongQuan(canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam) {
  const arr = [canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam]
  const arr_moc = []
  const arr_kim = []
  const counts = {};
  for (const num of arr) {
    if (counts[num] === "Mộc") {
      arr_moc.push(counts[num])
    } else if (counts[num] === "Kim")
      arr_kim.push(counts[num])
  }

  if (arr_moc.length === 4 && arr_kim.length === 4) {
    return "Thủy"
  } else {
    return ""
  }
}
function DieuHau(canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam) {
  const arr = [canGio, canNgay, canThang, canNam, chiGio, chiNgay, chiThang, chiNam]
  const arr_thuy = []
  const arr_hoa = []
  const counts = {};
  for (const num of arr) {
    if (counts[num] === "Thủy") {
      arr_thuy.push(counts[num])
    } else if (counts[num] === "Hỏa")
      arr_hoa.push(counts[num])
  }

  if (arr_thuy.length >= 6) {
    return "Hỏa"
  }
  else if (arr_hoa.length >= 6) {
    return "Thủy"
  }
  else {
    return ""
  }
}


export const hyperLinkNapAm = (str) => {
  return <a href="https://www.phongthuytuongminh.com/vi/giai-thich-y-nghia-cua-cac-ngu-hanh-nap-am.html" target="__blank">{str}</a>
}

export const hyperLinkTruongSinh = (str) => {

  return <a href="https://www.phongthuytuongminh.com/vi/vong-truong-sinh-trong-bat-tu-hieu-biet-sau-hon-de-cai-thien-cuoc-song.html
" target="__blank">{str}</a>
}

export const hyperLinkThanSat = (str) => {
  const arr_str = str.split(", ")
  return arr_str.map((item, index) => {
    return <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank"> {item}</a>
  })
}
export const hyperLinkThanSatStr = (str) => {
  return <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank"> {str}</a>
}
export const hyperLinkThanSatDiaChi = (arr) => {
  return arr.map((item, index) => {
    switch (item.name) {
      case 'Tướng Tinh':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-tuong-tinh.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Quý Nhân':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-thien-at-quy-nhan.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Văn Xương':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-van-xuong.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Đào Hoa':
        return <a key={index} href="https://www.phongthuytuongminh.com/en/bat-tu-than-sat-ham-tri-dao-hoa-sat.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Dịch Mã':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-dich-ma.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Quả Tú':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-co-than-qua-tu.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Cô Thần':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-co-than-qua-tu.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;
      case 'Kiếp Sát':
        return <a key={index} href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-kiep-sat.html" target="__blank" rel="noopener noreferrer"> {item.name}</a>
        break;

      default:
        break;
    }
  })
}

export const thanSatKhongVong = (str) => {
  return <a href="https://www.phongthuytuongminh.com/vi/bat-tu-than-sat-khong-vong.html
" target="__blank"> {str}</a>
}

export const hoaGiapThanSat = (str) => {
  switch (str) {
    case 'Giáp Tý':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tiến Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hàm Trì</a>
      </>
    case 'Ất Sửu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp </a>
      </>
    case 'Bính Dần':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường </a>
      </>
    case 'Đinh Mão':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hàm Trì, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thái Cực </a>
      </>
    case 'Mậu Thìn':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Đức </a>
      </>
    case 'Kỷ Tỵ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lục Hợp Quý, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn </a>
      </>
    case 'Canh Ngọ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hàm Trì, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>
    case 'Tân Mùi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
      </>
    case 'Nhâm Thân':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác </a>
      </>
    case 'Quý Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hàm Trì, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn </a>
      </>
    case 'Giáp Tuất':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Quốc Ấn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Ất Hợi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Quốc Ấn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>
    case 'Bính Tý':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phi Đao, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
      </>
    case 'Đinh Sửu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Mộ Khố, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương </a>
      </>
    case 'Mậu Dần':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường </a>
      </>
    case 'Kỷ Mão':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thất Sát, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tiến Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Canh Thìn':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lưu Hạ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác</a>
      </>
    case 'Tân Tỵ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Đa Mưu, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Quốc Ấn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>
    case 'Nhâm Ngọ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phi Đao, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Quý Mùi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phi Đao, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Mộ Khố, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Giáp Thân':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm </a>
      </>
    case 'Ất Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Bính Tuất':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Quả Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Mộ Khố, </a>
      </>
    case 'Đinh Hợi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">C.Q, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thiên Ất, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Quý </a>
      </>
    case 'Mậu Tý':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">C.T, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Đa Mưu, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Kỷ Sửu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tỷ Kiếp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phi Đao, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Canh Dần':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh </a>
      </>
    case 'Tân Mão':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lưu Hạ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Nhâm Thìn':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Mộ Khố, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thoái Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>

    case 'Quý Tỵ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thiên Ất, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Quý, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
      </>
    case 'Giáp Ngọ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">T.Quan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng* </a>
      </>
    case 'Ất Mùi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Bính Thân':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác </a>
      </>
    case 'Đinh Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thiên Ất, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thái Cực, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
      </>
    case 'Mậu Tuất':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Mộ Khố, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Kỷ Hợi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">C.T, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">C.Q, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn </a>
      </>
    case 'Canh Tý':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">T.Quan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp </a>
      </>
    case 'Tân Sửu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Nhâm Dần':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lưu Hạ </a>
      </>
    case 'Quý Mão':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thiên Ất, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Quý, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>
    case 'Giáp Thìn':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Quỹ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Dư </a>
      </>
    case 'Ất Tỵ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Quỹ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">T.Quan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Dư </a>
      </>
    case 'Bính Ngọ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kiếp Tài, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn </a>
      </>
    case 'Đinh Mùi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thoái Thần </a>
      </>
    case 'Mậu Thân':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">T.Quan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương </a>
      </>
    case 'Kỷ Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tiến Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Kỷ Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Văn Xương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Học Đường, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tiến Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Canh Tuất':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Quỹ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Tân Hợi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">T.Quan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Quỹ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kim Dư, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn </a>
      </>
    case 'Nhâm Tý':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kiếp Tài, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Quý Sửu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Giáp Dần':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tỷ Kiếp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Đức, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Ất Mão':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Bính Thìn':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Đức, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái </a>
      </>
    case 'Đinh Tỵ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+ Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Huyết Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Mậu Ngọ':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Linh, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cô Loan, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Cửu Xú </a>
      </>
    case 'Kỷ Mùi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Tú, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thái Cực, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Phúc* </a>
      </>
    case 'Canh Thân':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tỷ Kiếp, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lộc Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Tân Dậu':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hồng Diễm, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lộc Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Tướng*, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
    case 'Nhâm Tuất':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Khôi Cương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Hoa Cái, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Nhật Đức, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Thoái Thần, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">6 Hợp </a>
      </>
    case 'Quý Hợi':
      return <>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Kiếp Tài, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Lưu Hạ, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">10 Ác, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">Âm Dương, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">+Nhẫn, </a>
        <a href="https://www.phongthuytuongminh.com/vi/than-sat.html" target="__blank">8 Chuyên </a>
      </>
      break;

    default:
      break;
  }
}



